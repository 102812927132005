<template>
  <div>
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 400 850"
      enable-background="new 0 0 400 850"
      xml:space="preserve"
    >
      <g>
        <linearGradient
          id="group-15_1_"
          gradientUnits="userSpaceOnUse"
          x1="-43.8076"
          y1="652.8486"
          x2="34.6108"
          y2="652.8486"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-15"
          fill="url(#group-15_1_)"
          cx="-4.598"
          cy="652.849"
          r="39.209"
        />
        <g id="group-14">
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="90.0371"
            y1="367.9355"
            x2="53.1998"
            y2="308.4293"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.1"
            fill="url(#SVGID_1_)"
            points="-63.338,347.688 -42.086,447.904 222.059,287.804 108.927,232.548 		"
          />
          <g>
            <polygon
              fill="#EE97C2"
              points="11.87,29.624 -91.275,102.684 -86.258,240.061 -117.897,301.877 -107.157,375.473 108.927,179.7 
							"
            />
            <linearGradient
              id="SVGID_2_"
              gradientUnits="userSpaceOnUse"
              x1="-117.8975"
              y1="202.5483"
              x2="52.25"
              y2="202.5483"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_2_)"
              points="11.87,29.624 -91.275,102.684 -86.258,240.061 -117.897,301.877 
				-107.157,375.473 52.25,92.062 			"
            />
            <polygon
              fill="#39B1A7"
              points="-98.549,434.46 220.713,195.279 108.927,179.7 -107.157,375.473 			"
            />

            <linearGradient
              id="SVGID_3_"
              gradientUnits="userSpaceOnUse"
              x1="31.1689"
              y1="210.5293"
              x2="213.9053"
              y2="210.5293"
              gradientTransform="matrix(0.9895 -0.1444 0.1444 0.9895 -35.3604 -78.1729)"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_3_)"
              points="11.87,29.624 108.927,179.7 220.713,195.279 68.298,63.146 			"
            />
          </g>
        </g>
        <g id="group-13">
          <linearGradient
            id="SVGID_4_"
            gradientUnits="userSpaceOnUse"
            x1="266.2969"
            y1="237.792"
            x2="363.9978"
            y2="147.171"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.1"
            fill="url(#SVGID_4_)"
            points="234.111,148.39 241.52,209.822 349.897,238.533 461,176.455 461,62.401 		
			"
          />
          <g>
            <linearGradient
              id="SVGID_5_"
              gradientUnits="userSpaceOnUse"
              x1="345.416"
              y1="48.7578"
              x2="517.9912"
              y2="48.7578"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_5_)"
              points="355.383,-11.628 345.416,67.714 437.876,124.173 517.991,84.972 471.246,-26.658 			"
            />
            <polygon
              fill="#39B1A7"
              points="355,62.401 355,-11.628 143.835,14.583 192.517,147.056 			"
            />
            <linearGradient
              id="SVGID_6_"
              gradientUnits="userSpaceOnUse"
              x1="143.835"
              y1="41.0957"
              x2="355"
              y2="41.0957"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_6_)"
              points="355,14.583 355,-11.628 143.835,14.583 172.915,93.819 			"
            />
          </g>
        </g>
        <g id="group-12">
          <polygon
            fill="#39B1A7"
            points="-2.984,834.443 129.542,786.402 71.524,734.112 -36.109,815.078 		"
          />
          <polyline
            fill="#C96FA2"
            points="-77.797,796.115 -17.269,717.285 71.226,734.334 -36.098,815.02 -79.77,806.605 		"
          />
          <linearGradient
            id="SVGID_7_"
            gradientUnits="userSpaceOnUse"
            x1="-79.77"
            y1="766.1523"
            x2="12.9131"
            y2="766.1523"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop
              offset="0.9898"
              style="stop-color: #ffffff; stop-opacity: 0"
            />
          </linearGradient>
          <polyline
            opacity="0.7"
            fill="url(#SVGID_7_)"
            points="-77.797,796.115 -17.269,717.285 12.913,723.1 -36.098,815.02 
			-79.77,806.605 		"
          />
        </g>
        <g id="group-11">
          <linearGradient
            id="SVGID_8_"
            gradientUnits="userSpaceOnUse"
            x1="180.4141"
            y1="717.3662"
            x2="320.9492"
            y2="717.3662"
            gradientTransform="matrix(0.8507 -0.5256 0.5256 0.8507 -257.5283 284.8174)"
          >
            <stop offset="0" style="stop-color: #ffffff" />
            <stop offset="1" style="stop-color: #000000" />
          </linearGradient>
          <polygon
            opacity="0.2"
            fill="url(#SVGID_8_)"
            points="278.184,770.336 303.683,849.855 372.457,809.75 360.864,675.041 		"
          />
          <g>
            <polygon
              fill="#C96FA2"
              points="352.828,819.489 302.719,649.463 440.864,432.429 482.769,459.824 			"
            />

            <linearGradient
              id="SVGID_9_"
              gradientUnits="userSpaceOnUse"
              x1="261.9336"
              y1="590.748"
              x2="516.5332"
              y2="590.748"
              gradientTransform="matrix(0.8507 -0.5256 0.5256 0.8507 -257.5283 284.8174)"
            >
              <stop offset="0" style="stop-color: #ffffff" />
              <stop
                offset="0.9898"
                style="stop-color: #ffffff; stop-opacity: 0"
              />
            </linearGradient>
            <polygon
              opacity="0.7"
              fill="url(#SVGID_9_)"
              points="327.371,733.109 302.719,649.463 440.864,432.429 418.193,503.674 			"
            />

            <linearGradient
              id="SVGID_10_"
              gradientUnits="userSpaceOnUse"
              x1="238.1836"
              y1="667.6113"
              x2="592.9785"
              y2="667.6113"
              gradientTransform="matrix(0.8507 -0.5256 0.5256 0.8507 -257.5283 284.8174)"
            >
              <stop offset="0" style="stop-color: #f38b00" />
              <stop offset="0.1411" style="stop-color: #f59400" />
              <stop offset="0.3749" style="stop-color: #faad00" />
              <stop offset="0.5674" style="stop-color: #ffc700" />
              <stop offset="0.9944" style="stop-color: #f9e0a4" />
            </linearGradient>
            <polygon
              fill="url(#SVGID_10_)"
              points="529.47,430.396 482.464,459.442 352.701,819.284 568.656,712.01 			"
            />
            <polygon
              fill="#EE97C2"
              points="698.829,619.51 568.846,712.315 529.722,430.805 587.525,456.295 			"
            />
            <polygon
              fill="#39B1A7"
              points="440.73,432.332 535.949,373.496 587.225,456.48 529.47,430.396 482.464,459.442 			"
            />
          </g>
        </g>
        <g id="group-10">
          <path
            fill="#2A1F1D"
            d="M277.917,601.777c-27.756,0-50.336-22.58-50.336-50.336s22.58-50.336,50.336-50.336
			c27.755,0,50.336,22.58,50.336,50.336S305.672,601.777,277.917,601.777z M277.917,502.168c-27.17,0-49.273,22.104-49.273,49.273
			s22.104,49.273,49.273,49.273c27.169,0,49.273-22.104,49.273-49.273S305.086,502.168,277.917,502.168z"
          />
        </g>
        <circle
          id="group-09"
          fill="#E685B3"
          cx="175.3"
          cy="818.812"
          r="17.833"
        />
        <g id="group-08">
          <path
            fill="#2A1F1D"
            d="M50.01,613.746c-8.286,0-15.026-6.74-15.026-15.025c0-8.286,6.74-15.026,15.026-15.026
			c8.285,0,15.025,6.74,15.025,15.026C65.035,607.006,58.295,613.746,50.01,613.746z M50.01,584.757
			c-7.7,0-13.963,6.265-13.963,13.964s6.264,13.963,13.963,13.963c7.699,0,13.963-6.264,13.963-13.963S57.709,584.757,50.01,584.757
			z"
          />
        </g>
        <g id="group-07">
          <path
            fill="#2A1F1D"
            d="M56.337,712.519c-7.834,0-14.208-6.374-14.208-14.208s6.374-14.207,14.208-14.207
			s14.208,6.373,14.208,14.207S64.171,712.519,56.337,712.519z M56.337,685.166c-7.248,0-13.145,5.896-13.145,13.145
			s5.897,13.145,13.145,13.145s13.145-5.896,13.145-13.145S63.585,685.166,56.337,685.166z"
          />
        </g>
        <linearGradient
          id="group-06_1_"
          gradientUnits="userSpaceOnUse"
          x1="249.5176"
          y1="642.6318"
          x2="296.9336"
          y2="642.6318"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-06"
          fill="url(#group-06_1_)"
          cx="273.226"
          cy="642.633"
          r="23.708"
        />
        <linearGradient
          id="group-05_1_"
          gradientUnits="userSpaceOnUse"
          x1="283.0889"
          y1="157.415"
          x2="384.8848"
          y2="157.415"
        >
          <stop offset="0" style="stop-color: #94c0e9" />
          <stop offset="0.008" style="stop-color: #8bc1e9" />
          <stop offset="0.0212" style="stop-color: #71c5e8" />
          <stop offset="0.0323" style="stop-color: #57c9e8" />
          <stop offset="0.3186" style="stop-color: #4c9ed1" />
          <stop offset="0.6074" style="stop-color: #437bbd" />
          <stop offset="0.8431" style="stop-color: #3d64b1" />
          <stop offset="0.9944" style="stop-color: #3b5cad" />
        </linearGradient>
        <circle
          id="group-05"
          fill="url(#group-05_1_)"
          cx="333.987"
          cy="157.415"
          r="50.898"
        />
        <circle
          id="group-04"
          fill="#39B1A7"
          cx="344.953"
          cy="249.78"
          r="6.073"
        />
        <g id="group-03">
          <path
            fill="#2A1F1D"
            d="M293.275,250.15c-19.44,0-35.258-15.816-35.258-35.258s15.817-35.258,35.258-35.258
			c19.441,0,35.258,15.817,35.258,35.258S312.717,250.15,293.275,250.15z M293.275,180.697c-18.855,0-34.195,15.34-34.195,34.195
			s15.34,34.195,34.195,34.195s34.195-15.34,34.195-34.195S312.131,180.697,293.275,180.697z"
          />
        </g>
        <circle
          id="group-02"
          fill="#EE97C2"
          cx="214.323"
          cy="256.326"
          r="14.665"
        />
      </g>
    </svg>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'background',

  data() {
    return {
      //
    }
  },
}
</script>
