import { render, staticRenderFns } from "./background.vue?vue&type=template&id=082c4f34&"
import script from "./background.vue?vue&type=script&lang=js&"
export * from "./background.vue?vue&type=script&lang=js&"
import style0 from "./background.vue?vue&type=style&index=0&id=082c4f34&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports