<template>
  <div id="about">
    <component id="about-background" :is="selectedComponent"></component>

    <div id="upper-wrap">
      <div class="text-h3 font-weight-medium" id="about-title">
        A Bit About Me
      </div>
    </div>
    <div class="text-h3" id="text-01">
      <div class="">Talented Individual</div>
      <div class="">And Self Motivated</div>
    </div>
    <div class="text-h4" id="text-02">
      <div class="">Ownership, Responsibility</div>
      <div class="">And Problem Solving Skills</div>
    </div>
    <div class="text-h3" id="text-03">
      <div class="has-text-centered">Visually Inclined</div>
      <div class="has-text-centered">Tuning all details</div>
    </div>
    <img
      :src="personalPhoto"
      height="200px"
      width="200px"
      alt="Personal photo"
      id="personal-photo"
      srcset=""
    />
  </div>
</template>

<script>
import background from './background'
import backgroundMobile from './background-mobile'

export default {
  name: 'about',

  components: { background },

  data() {
    return {
      personalPhoto: require('@/assets/images/photo.png'),
    }
  },

  methods: {
    animate() {
      this.gsap.from('#about-background', {
        scrollTrigger: {
          trigger: '#about-background',
          toggleActions: 'restart pause reverse pause',
          start: 'top top',
          end: '2500px',
          pin: true,
        },
      })
      //about-title
      this.gsap.from('#about-title', {
        scrollTrigger: {
          trigger: '#about-title',
          toggleActions: 'restart pause reverse pause',
          start: 'top 2%',
          end: '2000px',
          pin: true,
        },
      })
      this.gsap.from('#about-title', {
        x: 200,
        opacity: 0,
        scrollTrigger: {
          trigger: '#about-title',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //text-01
      this.gsap.from('#text-01', {
        scrollTrigger: {
          trigger: '#text-01',
          toggleActions: 'restart pause reverse pause',
          start: 'top 20%',
          pin: true,
        },
      })
      this.gsap.from('#text-01', {
        x: 200,
        opacity: 0,
        scrollTrigger: {
          trigger: '#text-01',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //text-02
      this.gsap.from('#text-02', {
        scrollTrigger: {
          trigger: '#text-02',
          toggleActions: 'restart pause reverse pause',
          start: 'top 80%',
          pin: true,
        },
      })
      this.gsap.from('#text-02', {
        x: 10,
        opacity: 0,
        scrollTrigger: {
          trigger: '#text-02',
          toggleActions: 'play none none none',
          start: 'top 80%',
          scrub: 1,
        },
      })
      //text-03
      this.gsap.from('#text-03', {
        scrollTrigger: {
          trigger: '#text-03',
          toggleActions: 'restart pause reverse pause',
          start: 'top 80%',
          pin: true,
        },
      })
      this.gsap.from('#text-03', {
        x: 100,
        opacity: 0,
        scrollTrigger: {
          trigger: '#text-03',
          toggleActions: 'play none none none',
          start: 'top 80%',
          scrub: 1,
        },
      })
      // grid points top
      this.gsap.from('#path-05 *', {
        scale: 0.1,
        y: 6,
        ease: 'power1.inOut',
        stagger: {
          amount: 1.5,
          grid: 'auto',
          from: 'center',
        },
        scrollTrigger: {
          trigger: '#path-05',
          toggleActions: 'play none none none',
          start: 'top center',
          scrub: 1,
        },
      })
      //X's
      this.gsap.from('#path-12, #path-15, #path-16', {
        rotation: 360,
        transformOrigin: '50% 50%',
        scrollTrigger: {
          trigger: '#about-background',
          toggleActions: 'restart pause reverse pause',
          start: 'top center',
          end: '4000px',
          scrub: 1,
        },
      })
      // dots
      this.gsap.from('#path-17 *, #path-19 *', {
        opacity: 0.1,
        stagger: {
          amount: 1.5,
          grid: 'auto',
          from: 'center',
        },
        scrollTrigger: {
          trigger: '#about-background',
          toggleActions: 'restart pause reverse pause',
          start: 'top center',
          end: '3000px',
          scrub: 1,
        },
      })
      // waterfall
      this.gsap.to('#path-08', {
        x: -400,
        rotation: -90,
        transformOrigin: '50% 50%',
        scrollTrigger: {
          trigger: '#about-background',
          toggleActions: 'restart pause reverse pause',
          start: 'top center',
          end: '500px',
          scrub: 1,
        },
      })
      // left circle
      this.gsap.to('#path-09', {
        rotation: 180,
        transformOrigin: '50% 50%',
        x: -600,
        scrollTrigger: {
          trigger: '#text-03',
          toggleActions: 'play none none none',
          scrub: 1,
        },
      })
      // personal-photo
      this.gsap.from('#personal-photo', {
        opacity: 0,
        // x: -200,
        scrollTrigger: {
          trigger: '#text-03',
          toggleActions: 'play none none none',
          scrub: 1,
        },
      })
      // left grid
      this.gsap.from('#path-22 *', {
        duration: 1,
        scale: 0.1,
        y: 40,
        ease: 'power1.inOut',
        stagger: {
          grid: [7, 15],
          from: 'center',
          ease: 'power2.in',
          amount: 1.5,
        },
        scrollTrigger: {
          trigger: '#text-03',
          toggleActions: 'restart pause reverse pause',
          start: 'top center',
          scrub: 1,
        },
      })
      // Big X
      this.gsap.to('#path-04', {
        rotation: 360,
        transformOrigin: '50% 50%',
        x: 500,
        scrollTrigger: {
          trigger: '#text-02',
          toggleActions: 'restart pause reverse pause',
          start: 'top 70%',
          scrub: 1,
        },
      })
      // Zigzag
      this.gsap.to('#path-10', {
        x: -1200,
        scrollTrigger: {
          trigger: '#text-02',
          toggleActions: 'restart pause reverse pause',
          start: 'top center',
          scrub: 1,
        },
      })
      // motion path
      this.gsap.from('#path-20 #circle', {
        scrollTrigger: {
          trigger: '#about-background',
          toggleActions: 'play none none none',
          start: 'top center',
          end: '2000px',
          scrub: 1,
        },
        motionPath: {
          path: '#path-20 #path',
          align: '#path-20 #path',
          autoRotate: true,
          alignOrigin: [0.5, 0.5],
        },
      })
    },
  },

  mounted() {
    this.animate()
  },

  computed: {
    selectedComponent() {
      if (this.$vuetify.breakpoint.mobile) return backgroundMobile
      return background
    },
  },
}
</script>

<style>
#about {
  min-height: 768px;
  position: relative;
}

#upper-wrap {
  position: absolute;
  top: 5%;
  left: 30%;
}

#text-01 {
  position: absolute;
  top: 500px;
  left: 50%;
}

#text-02 {
  position: absolute;
  top: 1000px;
  right: 5%;
}

#text-03 {
  position: absolute;
  top: 1500px;
  left: 10%;
}

#personal-photo {
  position: absolute;
  top: 80%;
  left: 50px;
}
</style>
