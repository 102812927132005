<template>
  <div id="hero">
    <component :is="selectedComponent">
      <div id="hero-text">
        <h1
          class="text-h1 font-weight-medium text-center primary--text mb-2"
          id="hero-text-2"
        ></h1>
        <h1 class="text-h4 font-weight-light" id="hero-text-3">Professional</h1>
      </div>
      <div id="mouse-scroll-container">
        <div class="text-body-1 text-center mb-2">Scroll</div>
        <img
          :src="mouseScroll"
          height="50"
          width="50"
          alt=""
          id="mouse-scroll"
        />
      </div>
    </component>
  </div>
</template>

<script>
import background from './background'
import backgroundMobile from './background-mobile'

export default {
  name: 'hero',

  components: { background, backgroundMobile },

  data() {
    return {
      tl: null,
      mouseTl: null,
      mouseScrollContainerTl: null,
      mouseScroll: require('@/assets/images/mouse.svg'),
    }
  },

  methods: {
    animate() {
      this.tl.from('#hero', {
        autoAlpha: 0,
        duration: 0.5,
      })

      this.tl.to('#hero-text-2', {
        duration: 1.5,
        text: 'I am Wael',
        delay: 0.5,
      })

      this.tl.to('#hero-text-3', {
        duration: 0.4,
        autoAlpha: 1,
        display: 'block',
      })
      this.tl.to('#hero-text-3', {
        duration: 3,
        text: 'Full Stack Web Developer',
      })

      //mouse
      this.tl.to('#mouse-scroll-container', {
        x: 150,
        ease: 'power2.out',
        duration: 2,
      })
      this.mouseTl.to('#mouse-scroll', {
        delay: 1,
        y: 10,
        rotation: 20,
        duration: 0.5,
        ease: 'power2.out',
      })
      this.mouseTl.to('#mouse-scroll', {
        rotation: -20,
        duration: 0.5,
        ease: 'power2.out',
      })
      this.mouseTl.to('#mouse-scroll', {
        rotation: 0,
        y: 0,
        duration: 0.5,
        ease: 'power2.out',
      })

      this.mouseScrollContainerTl.to('#mouse-scroll-container', {
        x: -150,
        ease: 'power2.out',
        duration: 2,
      })
    },
  },

  created() {
    this.tl = this.gsap.timeline({})
    this.mouseScrollContainerTl = this.gsap.timeline({
      scrollTrigger: {
        trigger: '#hero-text',
        toggleActions: 'restart pause reverse pause',
        start: 'top top',
        end: '600px',
        scrub: 1,
      },
    })
    this.mouseTl = this.gsap.timeline({
      repeat: -1,
      repeatDelay: 2,
    })
  },

  mounted() {
    this.animate()
  },

  computed: {
    selectedComponent() {
      if (this.$vuetify.breakpoint.mobile) return backgroundMobile
      return background
    },
  },
}
</script>

<style>
#hero {
  min-height: 100vh;
  width: 100vw;
  position: relative;
  visibility: hidden;
}

#hero-text {
  position: absolute;
  top: calc(50% - 150px);
  left: calc(50% - 400px);
  width: 800px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

#hero-text-3 {
  visibility: hidden;
  text-align: center;
}

#mouse-scroll-container {
  position: absolute;
  left: -100px;
  top: 75vh;
}
</style>
