<template>
  <div id="home">
    <Hero ref="hero"></Hero>

    <About></About>

    <div class="next-section d-flex justify-center align-center">
      <router-link :to="{ name: 'whatIDo' }" class="text-h2 darkText--text">
        What I Do
        <v-icon color="" class="ms-2">
          {{ assets.mdiArrowRight }}
        </v-icon>
      </router-link>
    </div>
  </div>
</template>

<script>
import Hero from './hero/hero'
import About from './about/about'

import { mdiArrowRight } from '@mdi/js'

export default {
  name: 'home',

  components: { Hero, About },

  data() {
    return {
      assets: { mdiArrowRight },
    }
  },

  methods: {
    setup() {
      const rule = this.CSSRulePlugin.getRule('.next-section a:after')
      const element = document.querySelector('.next-section a')

      element.addEventListener('mouseenter', () => {
        this.gsap.to(rule, {
          width: '100%',
          duration: 0.5,
          marginLeft: '50%',
          ease: 'power2.out',
        })
      })
      element.addEventListener('mouseleave', () => {
        this.gsap.to(rule, {
          width: '0%',
          duration: 0.5,
          marginLeft: '50%',
          ease: 'power2.out',
        })
      })
    },
  },

  mounted() {
    this.setup()
  },

  metaInfo() {
    return {
      title: 'Crafting your next digital vision',
      titleTemplate: '%s - Wael Dev',
      meta: [
        {
          vmid: 'description',
          property: 'description',
          content:
            'Meet Wael, a skilled web developer who specializes in creating exceptional web applications and websites with the latest technologies. His profound expertise in design and passion for web development drives him to create stunning and functional applications that surpass client expectations. Get in touch with Wael today to discuss your project requirements.',
        },
        {
          vmid: 'language',
          property: 'language',
          content: 'en-EN',
        },
      ],
    }
  },
}
</script>

<style>
#home {
  min-height: 1000px;
}
</style>
