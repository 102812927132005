<template>
  <div>
    <svg
      version="1.1"
      id="BACKGROUND"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 1920 1080"
      enable-background="new 0 0 1920 1080"
      xml:space="preserve"
    >
      <g>
        <circle
          id="path-24"
          fill="#9EE0FF"
          cx="2232.051"
          cy="616.849"
          r="167.963"
        />
        <g id="path-23">
          <g>
            <path
              fill="#004FBD"
              d="M2085.988,616.849c0,2.08-1.682,3.766-3.764,3.766c-2.077,0-3.762-1.686-3.762-3.766
				c0-2.079,1.685-3.763,3.762-3.763C2084.308,613.086,2085.988,614.77,2085.988,616.849z"
            />
            <circle fill="#004FBD" cx="2094.711" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2107.198" cy="616.849" r="3.763" />
            <circle fill="#004FBD" cx="2119.684" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2132.168" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2144.651" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2157.141" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2169.623" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2182.11" cy="616.849" r="3.763" />
            <circle fill="#004FBD" cx="2194.596" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2207.081" cy="616.849" r="3.765" />
            <circle fill="#004FBD" cx="2219.564" cy="616.849" r="3.764" />
            <path
              fill="#004FBD"
              d="M2235.814,616.849c0,2.08-1.687,3.766-3.763,3.766c-2.082,0-3.769-1.686-3.769-3.766
				c0-2.079,1.687-3.763,3.769-3.763C2234.13,613.086,2235.814,614.77,2235.814,616.849z"
            />
            <path
              fill="#004FBD"
              d="M2248.302,616.849c0,2.08-1.687,3.766-3.768,3.766c-2.074,0-3.763-1.686-3.763-3.766
				c0-2.079,1.688-3.763,3.763-3.763C2246.615,613.086,2248.302,614.77,2248.302,616.849z"
            />
            <circle fill="#004FBD" cx="2257.021" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2269.506" cy="616.849" r="3.763" />
            <circle fill="#004FBD" cx="2281.992" cy="616.849" r="3.766" />
            <circle fill="#004FBD" cx="2294.479" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2306.963" cy="616.849" r="3.763" />
            <path
              fill="#004FBD"
              d="M2323.215,616.849c0,2.08-1.684,3.766-3.766,3.766c-2.079,0-3.767-1.686-3.767-3.766
				c0-2.079,1.688-3.763,3.767-3.763C2321.531,613.086,2323.215,614.77,2323.215,616.849z"
            />
            <circle fill="#004FBD" cx="2331.936" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2344.419" cy="616.849" r="3.763" />
            <circle fill="#004FBD" cx="2356.904" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2369.391" cy="616.849" r="3.764" />
            <circle fill="#004FBD" cx="2381.877" cy="616.849" r="3.764" />
          </g>
          <g>
            <g>
              <circle fill="#004FBD" cx="2094.711" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2107.198" cy="631.234" r="3.763" />
              <circle fill="#004FBD" cx="2119.684" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2132.168" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2144.651" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2157.141" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2169.623" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="631.234" r="3.762" />
              <circle fill="#004FBD" cx="2194.596" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="631.234" r="3.765" />
              <circle fill="#004FBD" cx="2219.564" cy="631.234" r="3.764" />
              <path
                fill="#004FBD"
                d="M2235.814,631.234c0,2.078-1.687,3.765-3.763,3.765c-2.082,0-3.769-1.687-3.769-3.765
					c0-2.08,1.687-3.766,3.769-3.766C2234.13,627.47,2235.814,629.154,2235.814,631.234z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,631.234c0,2.078-1.687,3.765-3.768,3.765c-2.074,0-3.763-1.687-3.763-3.765
					c0-2.08,1.688-3.766,3.763-3.766C2246.615,627.47,2248.302,629.154,2248.302,631.234z"
              />
              <circle fill="#004FBD" cx="2257.021" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2269.506" cy="631.234" r="3.763" />
              <circle fill="#004FBD" cx="2281.992" cy="631.234" r="3.766" />
              <circle fill="#004FBD" cx="2294.479" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2306.963" cy="631.234" r="3.763" />
              <path
                fill="#004FBD"
                d="M2323.215,631.234c0,2.078-1.684,3.765-3.766,3.765c-2.079,0-3.767-1.687-3.767-3.765
					c0-2.08,1.688-3.766,3.767-3.766C2321.531,627.47,2323.215,629.154,2323.215,631.234z"
              />
              <circle fill="#004FBD" cx="2331.936" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2344.419" cy="631.234" r="3.763" />
              <circle fill="#004FBD" cx="2356.904" cy="631.234" r="3.764" />
              <circle fill="#004FBD" cx="2369.391" cy="631.234" r="3.764" />
            </g>
            <g>
              <circle fill="#004FBD" cx="2094.711" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2107.198" cy="645.62" r="3.763" />
              <circle fill="#004FBD" cx="2119.684" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2132.168" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2144.651" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2157.141" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2169.623" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="645.62" r="3.762" />
              <circle fill="#004FBD" cx="2194.596" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="645.62" r="3.765" />
              <circle fill="#004FBD" cx="2219.564" cy="645.62" r="3.764" />
              <path
                fill="#004FBD"
                d="M2235.814,645.62c0,2.08-1.687,3.766-3.763,3.766c-2.082,0-3.769-1.686-3.769-3.766
					s1.687-3.765,3.769-3.765C2234.13,641.855,2235.814,643.54,2235.814,645.62z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,645.62c0,2.08-1.687,3.766-3.768,3.766c-2.074,0-3.763-1.686-3.763-3.766
					s1.688-3.765,3.763-3.765C2246.615,641.855,2248.302,643.54,2248.302,645.62z"
              />
              <circle fill="#004FBD" cx="2257.021" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2269.506" cy="645.62" r="3.763" />
              <circle fill="#004FBD" cx="2281.992" cy="645.62" r="3.766" />
              <circle fill="#004FBD" cx="2294.479" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2306.963" cy="645.62" r="3.763" />
              <path
                fill="#004FBD"
                d="M2323.215,645.62c0,2.08-1.684,3.766-3.766,3.766c-2.079,0-3.767-1.686-3.767-3.766
					s1.688-3.765,3.767-3.765C2321.531,641.855,2323.215,643.54,2323.215,645.62z"
              />
              <circle fill="#004FBD" cx="2331.936" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2344.419" cy="645.62" r="3.763" />
              <circle fill="#004FBD" cx="2356.904" cy="645.62" r="3.764" />
              <circle fill="#004FBD" cx="2369.391" cy="645.62" r="3.764" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2098.476,660.001c0,2.081-1.686,3.768-3.765,3.768c-2.08,0-3.766-1.687-3.766-3.768
					c0-2.077,1.686-3.763,3.766-3.763C2096.79,656.238,2098.476,657.924,2098.476,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2110.961,660.001c0,2.081-1.687,3.768-3.763,3.768c-2.08,0-3.765-1.687-3.765-3.768
					c0-2.077,1.685-3.763,3.765-3.763C2109.275,656.238,2110.961,657.924,2110.961,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2123.449,660.001c0,2.081-1.689,3.768-3.769,3.768s-3.763-1.687-3.763-3.768
					c0-2.077,1.684-3.763,3.763-3.763S2123.449,657.924,2123.449,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2135.934,660.001c0,2.081-1.688,3.768-3.766,3.768c-2.079,0-3.766-1.687-3.766-3.768
					c0-2.077,1.687-3.763,3.766-3.763S2135.934,657.924,2135.934,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2148.416,660.001c0,2.081-1.684,3.768-3.763,3.768s-3.769-1.687-3.769-3.768
					c0-2.077,1.688-3.763,3.769-3.763S2148.416,657.924,2148.416,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2160.905,660.001c0,2.081-1.688,3.768-3.77,3.768c-2.078,0-3.763-1.687-3.763-3.768
					c0-2.077,1.686-3.763,3.763-3.763C2159.218,656.238,2160.905,657.924,2160.905,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2173.388,660.001c0,2.081-1.686,3.768-3.764,3.768c-2.079,0-3.767-1.687-3.767-3.768
					c0-2.077,1.688-3.763,3.767-3.763S2173.388,657.924,2173.388,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,660.001c0,2.081-1.686,3.768-3.763,3.768c-2.079,0-3.763-1.687-3.763-3.768
					c0-2.077,1.684-3.763,3.763-3.763S2185.873,657.924,2185.873,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,660.001c0,2.081-1.686,3.768-3.765,3.768c-2.078,0-3.766-1.687-3.766-3.768
					c0-2.077,1.688-3.763,3.766-3.763C2196.676,656.238,2198.359,657.924,2198.359,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,660.001c0,2.081-1.687,3.768-3.767,3.768c-2.079,0-3.765-1.687-3.765-3.768
					c0-2.077,1.686-3.763,3.765-3.763C2209.159,656.238,2210.846,657.924,2210.846,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,660.001c0,2.081-1.686,3.768-3.764,3.768c-2.079,0-3.767-1.687-3.767-3.768
					c0-2.077,1.688-3.763,3.767-3.763C2221.646,656.238,2223.33,657.924,2223.33,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,660.001c0,2.081-1.687,3.768-3.763,3.768c-2.082,0-3.769-1.687-3.769-3.768
					c0-2.077,1.687-3.763,3.769-3.763C2234.13,656.238,2235.814,657.924,2235.814,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,660.001c0,2.081-1.687,3.768-3.768,3.768c-2.074,0-3.763-1.687-3.763-3.768
					c0-2.077,1.688-3.763,3.763-3.763C2246.615,656.238,2248.302,657.924,2248.302,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,660.001c0,2.081-1.684,3.768-3.763,3.768s-3.767-1.687-3.767-3.768
					c0-2.077,1.688-3.763,3.767-3.763C2259.104,656.238,2260.785,657.924,2260.785,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,660.001c0,2.081-1.685,3.768-3.764,3.768c-2.078,0-3.763-1.687-3.763-3.768
					c0-2.077,1.685-3.763,3.763-3.763C2271.586,656.238,2273.27,657.924,2273.27,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,660.001c0,2.081-1.684,3.768-3.763,3.768s-3.768-1.687-3.768-3.768
					c0-2.077,1.688-3.763,3.768-3.763S2285.758,657.924,2285.758,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,660.001c0,2.081-1.686,3.768-3.764,3.768c-2.08,0-3.766-1.687-3.766-3.768
					c0-2.077,1.686-3.763,3.766-3.763C2296.558,656.238,2298.242,657.924,2298.242,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2310.727,660.001c0,2.081-1.686,3.768-3.764,3.768c-2.079,0-3.763-1.687-3.763-3.768
					c0-2.077,1.684-3.763,3.763-3.763S2310.727,657.924,2310.727,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2323.215,660.001c0,2.081-1.684,3.768-3.766,3.768c-2.079,0-3.767-1.687-3.767-3.768
					c0-2.077,1.688-3.763,3.767-3.763C2321.531,656.238,2323.215,657.924,2323.215,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2335.698,660.001c0,2.081-1.688,3.768-3.763,3.768c-2.08,0-3.767-1.687-3.767-3.768
					c0-2.077,1.687-3.763,3.767-3.763C2334.014,656.238,2335.698,657.924,2335.698,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2348.182,660.001c0,2.081-1.684,3.768-3.763,3.768s-3.763-1.687-3.763-3.768
					c0-2.077,1.684-3.763,3.763-3.763S2348.182,657.924,2348.182,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2360.668,660.001c0,2.081-1.686,3.768-3.763,3.768c-2.079,0-3.767-1.687-3.767-3.768
					c0-2.077,1.688-3.763,3.767-3.763C2358.982,656.238,2360.668,657.924,2360.668,660.001z"
              />
              <path
                fill="#004FBD"
                d="M2373.154,660.001c0,2.081-1.686,3.768-3.765,3.768c-2.077,0-3.765-1.687-3.765-3.768
					c0-2.077,1.688-3.763,3.765-3.763C2371.47,656.238,2373.154,657.924,2373.154,660.001z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2110.961,674.39c0,2.077-1.687,3.764-3.763,3.764c-2.08,0-3.765-1.687-3.765-3.764
					c0-2.081,1.685-3.768,3.765-3.768C2109.275,670.622,2110.961,672.309,2110.961,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2123.449,674.39c0,2.077-1.689,3.764-3.769,3.764s-3.763-1.687-3.763-3.764
					c0-2.081,1.684-3.768,3.763-3.768S2123.449,672.309,2123.449,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2135.934,674.39c0,2.077-1.688,3.764-3.766,3.764c-2.079,0-3.766-1.687-3.766-3.764
					c0-2.081,1.687-3.768,3.766-3.768S2135.934,672.309,2135.934,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2148.416,674.39c0,2.077-1.684,3.764-3.763,3.764s-3.769-1.687-3.769-3.764
					c0-2.081,1.688-3.768,3.769-3.768S2148.416,672.309,2148.416,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2160.905,674.39c0,2.077-1.688,3.764-3.77,3.764c-2.078,0-3.763-1.687-3.763-3.764
					c0-2.081,1.686-3.768,3.763-3.768C2159.218,670.622,2160.905,672.309,2160.905,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2173.388,674.39c0,2.077-1.686,3.764-3.764,3.764c-2.079,0-3.767-1.687-3.767-3.764
					c0-2.081,1.688-3.768,3.767-3.768S2173.388,672.309,2173.388,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,674.39c0,2.077-1.686,3.764-3.763,3.764c-2.079,0-3.763-1.687-3.763-3.764
					c0-2.081,1.684-3.768,3.763-3.768S2185.873,672.309,2185.873,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,674.39c0,2.077-1.686,3.764-3.765,3.764c-2.078,0-3.766-1.687-3.766-3.764
					c0-2.081,1.688-3.768,3.766-3.768C2196.676,670.622,2198.359,672.309,2198.359,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,674.39c0,2.077-1.687,3.764-3.767,3.764c-2.079,0-3.765-1.687-3.765-3.764
					c0-2.081,1.686-3.768,3.765-3.768C2209.159,670.622,2210.846,672.309,2210.846,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,674.39c0,2.077-1.686,3.764-3.764,3.764c-2.079,0-3.767-1.687-3.767-3.764
					c0-2.081,1.688-3.768,3.767-3.768C2221.646,670.622,2223.33,672.309,2223.33,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,674.39c0,2.077-1.687,3.764-3.763,3.764c-2.082,0-3.769-1.687-3.769-3.764
					c0-2.081,1.687-3.768,3.769-3.768C2234.13,670.622,2235.814,672.309,2235.814,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,674.39c0,2.077-1.687,3.764-3.768,3.764c-2.074,0-3.763-1.687-3.763-3.764
					c0-2.081,1.688-3.768,3.763-3.768C2246.615,670.622,2248.302,672.309,2248.302,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,674.39c0,2.077-1.684,3.764-3.763,3.764s-3.767-1.687-3.767-3.764
					c0-2.081,1.688-3.768,3.767-3.768C2259.104,670.622,2260.785,672.309,2260.785,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,674.39c0,2.077-1.685,3.764-3.764,3.764c-2.078,0-3.763-1.687-3.763-3.764
					c0-2.081,1.685-3.768,3.763-3.768C2271.586,670.622,2273.27,672.309,2273.27,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,674.39c0,2.077-1.684,3.764-3.763,3.764s-3.768-1.687-3.768-3.764
					c0-2.081,1.688-3.768,3.768-3.768S2285.758,672.309,2285.758,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,674.39c0,2.077-1.686,3.764-3.764,3.764c-2.08,0-3.766-1.687-3.766-3.764
					c0-2.081,1.686-3.768,3.766-3.768C2296.558,670.622,2298.242,672.309,2298.242,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2310.727,674.39c0,2.077-1.686,3.764-3.764,3.764c-2.079,0-3.763-1.687-3.763-3.764
					c0-2.081,1.684-3.768,3.763-3.768S2310.727,672.309,2310.727,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2323.215,674.39c0,2.077-1.684,3.764-3.766,3.764c-2.079,0-3.767-1.687-3.767-3.764
					c0-2.081,1.688-3.768,3.767-3.768C2321.531,670.622,2323.215,672.309,2323.215,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2335.698,674.39c0,2.077-1.688,3.764-3.763,3.764c-2.08,0-3.767-1.687-3.767-3.764
					c0-2.081,1.687-3.768,3.767-3.768C2334.014,670.622,2335.698,672.309,2335.698,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2348.182,674.39c0,2.077-1.684,3.764-3.763,3.764s-3.763-1.687-3.763-3.764
					c0-2.081,1.684-3.768,3.763-3.768S2348.182,672.309,2348.182,674.39z"
              />
              <path
                fill="#004FBD"
                d="M2360.668,674.39c0,2.077-1.686,3.764-3.763,3.764c-2.079,0-3.767-1.687-3.767-3.764
					c0-2.081,1.688-3.768,3.767-3.768C2358.982,670.622,2360.668,672.309,2360.668,674.39z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2107.198" cy="688.773" r="3.763" />
              <path
                fill="#004FBD"
                d="M2123.449,688.773c0,2.078-1.689,3.767-3.769,3.767s-3.763-1.688-3.763-3.767
					c0-2.08,1.684-3.765,3.763-3.765S2123.449,686.693,2123.449,688.773z"
              />
              <circle fill="#004FBD" cx="2132.168" cy="688.773" r="3.765" />
              <circle fill="#004FBD" cx="2144.651" cy="688.773" r="3.765" />
              <path
                fill="#004FBD"
                d="M2160.905,688.773c0,2.078-1.688,3.767-3.77,3.767c-2.078,0-3.763-1.688-3.763-3.767
					c0-2.08,1.686-3.765,3.763-3.765C2159.218,685.01,2160.905,686.693,2160.905,688.773z"
              />
              <circle fill="#004FBD" cx="2169.623" cy="688.773" r="3.764" />
              <ellipse
                fill="#004FBD"
                cx="2182.11"
                cy="688.773"
                rx="3.767"
                ry="3.767"
              />
              <circle fill="#004FBD" cx="2194.596" cy="688.773" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="688.773" r="3.765" />
              <circle fill="#004FBD" cx="2219.564" cy="688.773" r="3.764" />
              <path
                fill="#004FBD"
                d="M2235.814,688.773c0,2.078-1.687,3.767-3.763,3.767c-2.082,0-3.769-1.688-3.769-3.767
					c0-2.08,1.687-3.765,3.769-3.765C2234.13,685.01,2235.814,686.693,2235.814,688.773z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,688.773c0,2.078-1.687,3.767-3.768,3.767c-2.074,0-3.763-1.688-3.763-3.767
					c0-2.08,1.688-3.765,3.763-3.765C2246.615,685.01,2248.302,686.693,2248.302,688.773z"
              />
              <circle fill="#004FBD" cx="2257.021" cy="688.773" r="3.764" />
              <circle fill="#004FBD" cx="2269.506" cy="688.773" r="3.763" />
              <circle fill="#004FBD" cx="2281.992" cy="688.773" r="3.766" />
              <circle fill="#004FBD" cx="2294.479" cy="688.773" r="3.764" />
              <circle fill="#004FBD" cx="2306.963" cy="688.773" r="3.763" />
              <path
                fill="#004FBD"
                d="M2323.215,688.773c0,2.078-1.684,3.767-3.766,3.767c-2.079,0-3.767-1.688-3.767-3.767
					c0-2.08,1.688-3.765,3.767-3.765C2321.531,685.01,2323.215,686.693,2323.215,688.773z"
              />
              <circle fill="#004FBD" cx="2331.936" cy="688.773" r="3.764" />
              <circle fill="#004FBD" cx="2344.419" cy="688.773" r="3.763" />
              <circle fill="#004FBD" cx="2356.904" cy="688.773" r="3.764" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2123.449,703.16c0,2.078-1.689,3.765-3.769,3.765s-3.763-1.687-3.763-3.765
					c0-2.082,1.684-3.767,3.763-3.767S2123.449,701.078,2123.449,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2135.934,703.16c0,2.078-1.688,3.765-3.766,3.765c-2.079,0-3.766-1.687-3.766-3.765
					c0-2.082,1.687-3.767,3.766-3.767S2135.934,701.078,2135.934,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2148.416,703.16c0,2.078-1.684,3.765-3.763,3.765s-3.769-1.687-3.769-3.765
					c0-2.082,1.688-3.767,3.769-3.767S2148.416,701.078,2148.416,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2160.905,703.16c0,2.078-1.688,3.765-3.77,3.765c-2.078,0-3.763-1.687-3.763-3.765
					c0-2.082,1.686-3.767,3.763-3.767C2159.218,699.395,2160.905,701.078,2160.905,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2173.388,703.16c0,2.078-1.686,3.765-3.764,3.765c-2.079,0-3.767-1.687-3.767-3.765
					c0-2.082,1.688-3.767,3.767-3.767S2173.388,701.078,2173.388,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,703.16c0,2.078-1.686,3.765-3.763,3.765c-2.079,0-3.763-1.687-3.763-3.765
					c0-2.082,1.684-3.767,3.763-3.767S2185.873,701.078,2185.873,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,703.16c0,2.078-1.686,3.765-3.765,3.765c-2.078,0-3.766-1.687-3.766-3.765
					c0-2.082,1.688-3.767,3.766-3.767C2196.676,699.395,2198.359,701.078,2198.359,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,703.16c0,2.078-1.687,3.765-3.767,3.765c-2.079,0-3.765-1.687-3.765-3.765
					c0-2.082,1.686-3.767,3.765-3.767C2209.159,699.395,2210.846,701.078,2210.846,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,703.16c0,2.078-1.686,3.765-3.764,3.765c-2.079,0-3.767-1.687-3.767-3.765
					c0-2.082,1.688-3.767,3.767-3.767C2221.646,699.395,2223.33,701.078,2223.33,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,703.16c0,2.078-1.687,3.765-3.763,3.765c-2.082,0-3.769-1.687-3.769-3.765
					c0-2.082,1.687-3.767,3.769-3.767C2234.13,699.395,2235.814,701.078,2235.814,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,703.16c0,2.078-1.687,3.765-3.768,3.765c-2.074,0-3.763-1.687-3.763-3.765
					c0-2.082,1.688-3.767,3.763-3.767C2246.615,699.395,2248.302,701.078,2248.302,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,703.16c0,2.078-1.684,3.765-3.763,3.765s-3.767-1.687-3.767-3.765
					c0-2.082,1.688-3.767,3.767-3.767C2259.104,699.395,2260.785,701.078,2260.785,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,703.16c0,2.078-1.685,3.765-3.764,3.765c-2.078,0-3.763-1.687-3.763-3.765
					c0-2.082,1.685-3.767,3.763-3.767C2271.586,699.395,2273.27,701.078,2273.27,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,703.16c0,2.078-1.684,3.765-3.763,3.765s-3.768-1.687-3.768-3.765
					c0-2.082,1.688-3.767,3.768-3.767S2285.758,701.078,2285.758,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,703.16c0,2.078-1.686,3.765-3.764,3.765c-2.08,0-3.766-1.687-3.766-3.765
					c0-2.082,1.686-3.767,3.766-3.767C2296.558,699.395,2298.242,701.078,2298.242,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2310.727,703.16c0,2.078-1.686,3.765-3.764,3.765c-2.079,0-3.763-1.687-3.763-3.765
					c0-2.082,1.684-3.767,3.763-3.767S2310.727,701.078,2310.727,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2323.215,703.16c0,2.078-1.684,3.765-3.766,3.765c-2.079,0-3.767-1.687-3.767-3.765
					c0-2.082,1.688-3.767,3.767-3.767C2321.531,699.395,2323.215,701.078,2323.215,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2335.698,703.16c0,2.078-1.688,3.765-3.763,3.765c-2.08,0-3.767-1.687-3.767-3.765
					c0-2.082,1.687-3.767,3.767-3.767C2334.014,699.395,2335.698,701.078,2335.698,703.16z"
              />
              <path
                fill="#004FBD"
                d="M2348.182,703.16c0,2.078-1.684,3.765-3.763,3.765s-3.763-1.687-3.763-3.765
					c0-2.082,1.684-3.767,3.763-3.767S2348.182,701.078,2348.182,703.16z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2135.934,717.544c0,2.081-1.688,3.767-3.766,3.767c-2.079,0-3.766-1.686-3.766-3.767
					c0-2.078,1.687-3.762,3.766-3.762S2135.934,715.466,2135.934,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2148.416,717.544c0,2.081-1.684,3.767-3.763,3.767s-3.769-1.686-3.769-3.767
					c0-2.078,1.688-3.762,3.769-3.762S2148.416,715.466,2148.416,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2160.905,717.544c0,2.081-1.688,3.767-3.77,3.767c-2.078,0-3.763-1.686-3.763-3.767
					c0-2.078,1.686-3.762,3.763-3.762C2159.218,713.782,2160.905,715.466,2160.905,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2173.388,717.544c0,2.081-1.686,3.767-3.764,3.767c-2.079,0-3.767-1.686-3.767-3.767
					c0-2.078,1.688-3.762,3.767-3.762S2173.388,715.466,2173.388,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,717.544c0,2.081-1.686,3.767-3.763,3.767c-2.079,0-3.763-1.686-3.763-3.767
					c0-2.078,1.684-3.762,3.763-3.762S2185.873,715.466,2185.873,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,717.544c0,2.081-1.686,3.767-3.765,3.767c-2.078,0-3.766-1.686-3.766-3.767
					c0-2.078,1.688-3.762,3.766-3.762C2196.676,713.782,2198.359,715.466,2198.359,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,717.544c0,2.081-1.687,3.767-3.767,3.767c-2.079,0-3.765-1.686-3.765-3.767
					c0-2.078,1.686-3.762,3.765-3.762C2209.159,713.782,2210.846,715.466,2210.846,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,717.544c0,2.081-1.686,3.767-3.764,3.767c-2.079,0-3.767-1.686-3.767-3.767
					c0-2.078,1.688-3.762,3.767-3.762C2221.646,713.782,2223.33,715.466,2223.33,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,717.544c0,2.081-1.687,3.767-3.763,3.767c-2.082,0-3.769-1.686-3.769-3.767
					c0-2.078,1.687-3.762,3.769-3.762C2234.13,713.782,2235.814,715.466,2235.814,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,717.544c0,2.081-1.687,3.767-3.768,3.767c-2.074,0-3.763-1.686-3.763-3.767
					c0-2.078,1.688-3.762,3.763-3.762C2246.615,713.782,2248.302,715.466,2248.302,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,717.544c0,2.081-1.684,3.767-3.763,3.767s-3.767-1.686-3.767-3.767
					c0-2.078,1.688-3.762,3.767-3.762C2259.104,713.782,2260.785,715.466,2260.785,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,717.544c0,2.081-1.685,3.767-3.764,3.767c-2.078,0-3.763-1.686-3.763-3.767
					c0-2.078,1.685-3.762,3.763-3.762C2271.586,713.782,2273.27,715.466,2273.27,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,717.544c0,2.081-1.684,3.767-3.763,3.767s-3.768-1.686-3.768-3.767
					c0-2.078,1.688-3.762,3.768-3.762S2285.758,715.466,2285.758,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,717.544c0,2.081-1.686,3.767-3.764,3.767c-2.08,0-3.766-1.686-3.766-3.767
					c0-2.078,1.686-3.762,3.766-3.762C2296.558,713.782,2298.242,715.466,2298.242,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2310.727,717.544c0,2.081-1.686,3.767-3.764,3.767c-2.079,0-3.763-1.686-3.763-3.767
					c0-2.078,1.684-3.762,3.763-3.762S2310.727,715.466,2310.727,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2323.215,717.544c0,2.081-1.684,3.767-3.766,3.767c-2.079,0-3.767-1.686-3.767-3.767
					c0-2.078,1.688-3.762,3.767-3.762C2321.531,713.782,2323.215,715.466,2323.215,717.544z"
              />
              <path
                fill="#004FBD"
                d="M2335.698,717.544c0,2.081-1.688,3.767-3.763,3.767c-2.08,0-3.767-1.686-3.767-3.767
					c0-2.078,1.687-3.762,3.767-3.762C2334.014,713.782,2335.698,715.466,2335.698,717.544z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2148.416,731.932c0,2.077-1.684,3.765-3.763,3.765s-3.769-1.688-3.769-3.765
					c0-2.08,1.688-3.766,3.769-3.766S2148.416,729.851,2148.416,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2160.905,731.932c0,2.077-1.688,3.765-3.77,3.765c-2.078,0-3.763-1.688-3.763-3.765
					c0-2.08,1.686-3.766,3.763-3.766C2159.218,728.166,2160.905,729.851,2160.905,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2173.388,731.932c0,2.077-1.686,3.765-3.764,3.765c-2.079,0-3.767-1.688-3.767-3.765
					c0-2.08,1.688-3.766,3.767-3.766S2173.388,729.851,2173.388,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,731.932c0,2.077-1.686,3.765-3.763,3.765c-2.079,0-3.763-1.688-3.763-3.765
					c0-2.08,1.684-3.766,3.763-3.766S2185.873,729.851,2185.873,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,731.932c0,2.077-1.686,3.765-3.765,3.765c-2.078,0-3.766-1.688-3.766-3.765
					c0-2.08,1.688-3.766,3.766-3.766C2196.676,728.166,2198.359,729.851,2198.359,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,731.932c0,2.077-1.687,3.765-3.767,3.765c-2.079,0-3.765-1.688-3.765-3.765
					c0-2.08,1.686-3.766,3.765-3.766C2209.159,728.166,2210.846,729.851,2210.846,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,731.932c0,2.077-1.686,3.765-3.764,3.765c-2.079,0-3.767-1.688-3.767-3.765
					c0-2.08,1.688-3.766,3.767-3.766C2221.646,728.166,2223.33,729.851,2223.33,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,731.932c0,2.077-1.687,3.765-3.763,3.765c-2.082,0-3.769-1.688-3.769-3.765
					c0-2.08,1.687-3.766,3.769-3.766C2234.13,728.166,2235.814,729.851,2235.814,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,731.932c0,2.077-1.687,3.765-3.768,3.765c-2.074,0-3.763-1.688-3.763-3.765
					c0-2.08,1.688-3.766,3.763-3.766C2246.615,728.166,2248.302,729.851,2248.302,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,731.932c0,2.077-1.684,3.765-3.763,3.765s-3.767-1.688-3.767-3.765
					c0-2.08,1.688-3.766,3.767-3.766C2259.104,728.166,2260.785,729.851,2260.785,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,731.932c0,2.077-1.685,3.765-3.764,3.765c-2.078,0-3.763-1.688-3.763-3.765
					c0-2.08,1.685-3.766,3.763-3.766C2271.586,728.166,2273.27,729.851,2273.27,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,731.932c0,2.077-1.684,3.765-3.763,3.765s-3.768-1.688-3.768-3.765
					c0-2.08,1.688-3.766,3.768-3.766S2285.758,729.851,2285.758,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,731.932c0,2.077-1.686,3.765-3.764,3.765c-2.08,0-3.766-1.688-3.766-3.765
					c0-2.08,1.686-3.766,3.766-3.766C2296.558,728.166,2298.242,729.851,2298.242,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2310.727,731.932c0,2.077-1.686,3.765-3.764,3.765c-2.079,0-3.763-1.688-3.763-3.765
					c0-2.08,1.684-3.766,3.763-3.766S2310.727,729.851,2310.727,731.932z"
              />
              <path
                fill="#004FBD"
                d="M2323.215,731.932c0,2.077-1.684,3.765-3.766,3.765c-2.079,0-3.767-1.688-3.767-3.765
					c0-2.08,1.688-3.766,3.767-3.766C2321.531,728.166,2323.215,729.851,2323.215,731.932z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2173.388,746.315c0,2.076-1.686,3.764-3.764,3.764c-2.079,0-3.767-1.688-3.767-3.764
					c0-2.081,1.688-3.767,3.767-3.767S2173.388,744.234,2173.388,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2185.873,746.315c0,2.076-1.686,3.764-3.763,3.764c-2.079,0-3.763-1.688-3.763-3.764
					c0-2.081,1.684-3.767,3.763-3.767S2185.873,744.234,2185.873,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2198.359,746.315c0,2.076-1.686,3.764-3.765,3.764c-2.078,0-3.766-1.688-3.766-3.764
					c0-2.081,1.688-3.767,3.766-3.767C2196.676,742.55,2198.359,744.234,2198.359,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2210.846,746.315c0,2.076-1.687,3.764-3.767,3.764c-2.079,0-3.765-1.688-3.765-3.764
					c0-2.081,1.686-3.767,3.765-3.767C2209.159,742.55,2210.846,744.234,2210.846,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2223.33,746.315c0,2.076-1.686,3.764-3.764,3.764c-2.079,0-3.767-1.688-3.767-3.764
					c0-2.081,1.688-3.767,3.767-3.767C2221.646,742.55,2223.33,744.234,2223.33,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2235.814,746.315c0,2.076-1.687,3.764-3.763,3.764c-2.082,0-3.769-1.688-3.769-3.764
					c0-2.081,1.687-3.767,3.769-3.767C2234.13,742.55,2235.814,744.234,2235.814,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,746.315c0,2.076-1.687,3.764-3.768,3.764c-2.074,0-3.763-1.688-3.763-3.764
					c0-2.081,1.688-3.767,3.763-3.767C2246.615,742.55,2248.302,744.234,2248.302,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2260.785,746.315c0,2.076-1.684,3.764-3.763,3.764s-3.767-1.688-3.767-3.764
					c0-2.081,1.688-3.767,3.767-3.767C2259.104,742.55,2260.785,744.234,2260.785,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2273.27,746.315c0,2.076-1.685,3.764-3.764,3.764c-2.078,0-3.763-1.688-3.763-3.764
					c0-2.081,1.685-3.767,3.763-3.767C2271.586,742.55,2273.27,744.234,2273.27,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2285.758,746.315c0,2.076-1.684,3.764-3.763,3.764s-3.768-1.688-3.768-3.764
					c0-2.081,1.688-3.767,3.768-3.767S2285.758,744.234,2285.758,746.315z"
              />
              <path
                fill="#004FBD"
                d="M2298.242,746.315c0,2.076-1.686,3.764-3.764,3.764c-2.08,0-3.766-1.688-3.766-3.764
					c0-2.081,1.686-3.767,3.766-3.767C2296.558,742.55,2298.242,744.234,2298.242,746.315z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2194.596" cy="760.7" r="3.765" />
              <circle fill="#004FBD" cx="2207.081" cy="760.7" r="3.765" />
              <circle fill="#004FBD" cx="2219.564" cy="760.7" r="3.765" />
              <path
                fill="#004FBD"
                d="M2235.814,760.699c0,2.082-1.687,3.766-3.763,3.766c-2.082,0-3.769-1.684-3.769-3.766
					c0-2.076,1.687-3.764,3.769-3.764C2234.13,756.938,2235.814,758.622,2235.814,760.699z"
              />
              <path
                fill="#004FBD"
                d="M2248.302,760.699c0,2.082-1.687,3.766-3.768,3.766c-2.074,0-3.763-1.684-3.763-3.766
					c0-2.076,1.688-3.764,3.763-3.764C2246.615,756.938,2248.302,758.622,2248.302,760.699z"
              />
              <circle fill="#004FBD" cx="2257.021" cy="760.7" r="3.765" />
              <circle fill="#004FBD" cx="2269.506" cy="760.7" r="3.763" />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#004FBD"
                d="M2365.626,602.467c0-2.082,1.687-3.767,3.765-3.767c2.079,0,3.765,1.685,3.765,3.767
					c0,2.076-1.686,3.761-3.765,3.761C2367.313,606.229,2365.626,604.543,2365.626,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2353.14,602.467c0-2.082,1.688-3.767,3.765-3.767c2.078,0,3.765,1.685,3.765,3.767
					c0,2.076-1.686,3.761-3.765,3.761C2354.826,606.229,2353.14,604.543,2353.14,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2340.656,602.467c0-2.082,1.684-3.767,3.763-3.767s3.763,1.685,3.763,3.767
					c0,2.076-1.684,3.761-3.763,3.761S2340.656,604.543,2340.656,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2328.17,602.467c0-2.082,1.687-3.767,3.766-3.767c2.076,0,3.764,1.685,3.764,3.767
					c0,2.076-1.688,3.761-3.764,3.761C2329.855,606.229,2328.17,604.543,2328.17,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2315.684,602.467c0-2.082,1.688-3.767,3.767-3.767c2.082,0,3.766,1.685,3.766,3.767
					c0,2.076-1.684,3.761-3.766,3.761C2317.37,606.229,2315.684,604.543,2315.684,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2303.2,602.467c0-2.082,1.684-3.767,3.763-3.767c2.078,0,3.764,1.685,3.764,3.767
					c0,2.076-1.686,3.761-3.764,3.761C2304.884,606.229,2303.2,604.543,2303.2,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2290.714,602.467c0-2.082,1.686-3.767,3.767-3.767c2.077,0,3.763,1.685,3.763,3.767
					c0,2.076-1.686,3.761-3.763,3.761C2292.398,606.229,2290.714,604.543,2290.714,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2278.227,602.467c0-2.082,1.689-3.767,3.77-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.076-1.685,3.761-3.763,3.761C2279.916,606.229,2278.227,604.543,2278.227,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2265.743,602.467c0-2.082,1.685-3.767,3.763-3.767c2.079,0,3.764,1.685,3.764,3.767
					c0,2.076-1.685,3.761-3.764,3.761C2267.428,606.229,2265.743,604.543,2265.743,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2253.258,602.467c0-2.082,1.687-3.767,3.766-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.076-1.685,3.761-3.763,3.761C2254.944,606.228,2253.258,604.543,2253.258,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2240.771,602.467c0-2.082,1.688-3.767,3.763-3.767c2.081,0,3.768,1.685,3.768,3.767
					c0,2.076-1.687,3.761-3.768,3.761C2242.46,606.229,2240.771,604.543,2240.771,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,602.467c0-2.082,1.687-3.767,3.769-3.767c2.076,0,3.764,1.685,3.764,3.767
					c0,2.076-1.688,3.761-3.764,3.761C2229.971,606.229,2228.284,604.543,2228.284,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2215.801,602.467c0-2.082,1.688-3.767,3.767-3.767s3.764,1.685,3.764,3.767
					c0,2.076-1.685,3.761-3.764,3.761S2215.801,604.543,2215.801,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2203.314,602.467c0-2.082,1.686-3.767,3.766-3.767c2.079,0,3.766,1.685,3.766,3.767
					c0,2.076-1.687,3.761-3.766,3.761C2205,606.229,2203.314,604.543,2203.314,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2190.83,602.467c0-2.082,1.688-3.767,3.766-3.767c2.079,0,3.765,1.685,3.765,3.767
					c0,2.076-1.686,3.761-3.765,3.761C2192.518,606.229,2190.83,604.543,2190.83,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2178.348,602.467c0-2.082,1.685-3.767,3.764-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.076-1.686,3.761-3.763,3.761C2180.031,606.229,2178.348,604.543,2178.348,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2165.859,602.467c0-2.082,1.686-3.767,3.766-3.767c2.078,0,3.764,1.685,3.764,3.767
					c0,2.076-1.686,3.761-3.764,3.761C2167.545,606.229,2165.859,604.543,2165.859,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2153.374,602.467c0-2.082,1.685-3.767,3.763-3.767c2.08,0,3.77,1.685,3.77,3.767
					c0,2.076-1.688,3.761-3.77,3.761C2155.059,606.229,2153.374,604.543,2153.374,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2140.887,602.467c0-2.082,1.688-3.767,3.769-3.767c2.079,0,3.764,1.685,3.764,3.767
					c0,2.076-1.685,3.761-3.764,3.761S2140.887,604.543,2140.887,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2128.402,602.467c0-2.082,1.688-3.767,3.766-3.767c2.079,0,3.766,1.685,3.766,3.767
					c0,2.076-1.687,3.761-3.766,3.761S2128.402,604.543,2128.402,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2115.919,602.467c0-2.082,1.685-3.767,3.763-3.767c2.079,0,3.77,1.685,3.77,3.767
					c0,2.076-1.689,3.761-3.77,3.761S2115.919,604.543,2115.919,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2103.436,602.467c0-2.082,1.684-3.767,3.764-3.767c2.076,0,3.763,1.685,3.763,3.767
					c0,2.076-1.687,3.761-3.763,3.761C2105.118,606.229,2103.436,604.543,2103.436,602.467z"
              />
              <path
                fill="#004FBD"
                d="M2090.946,602.467c0-2.082,1.687-3.767,3.767-3.767c2.078,0,3.766,1.685,3.766,3.767
					c0,2.076-1.688,3.761-3.766,3.761C2092.633,606.229,2090.946,604.543,2090.946,602.467z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2369.391" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2356.904" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2344.419" cy="588.08" r="3.763" />
              <circle fill="#004FBD" cx="2331.936" cy="588.08" r="3.764" />
              <path
                fill="#004FBD"
                d="M2315.684,588.08c0-2.079,1.688-3.763,3.767-3.763c2.082,0,3.766,1.684,3.766,3.763
					c0,2.08-1.684,3.764-3.766,3.764C2317.37,591.844,2315.684,590.159,2315.684,588.08z"
              />
              <circle fill="#004FBD" cx="2306.963" cy="588.08" r="3.763" />
              <circle fill="#004FBD" cx="2294.479" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2281.992" cy="588.08" r="3.766" />
              <circle fill="#004FBD" cx="2269.506" cy="588.08" r="3.763" />
              <circle fill="#004FBD" cx="2257.021" cy="588.08" r="3.764" />
              <path
                fill="#004FBD"
                d="M2240.771,588.08c0-2.079,1.688-3.763,3.763-3.763c2.081,0,3.768,1.684,3.768,3.763
					c0,2.08-1.687,3.764-3.768,3.764C2242.46,591.844,2240.771,590.159,2240.771,588.08z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,588.08c0-2.079,1.687-3.763,3.769-3.763c2.076,0,3.764,1.684,3.764,3.763
					c0,2.08-1.688,3.764-3.764,3.764C2229.971,591.844,2228.284,590.159,2228.284,588.08z"
              />
              <circle fill="#004FBD" cx="2219.564" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="588.08" r="3.765" />
              <circle fill="#004FBD" cx="2194.596" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="588.08" r="3.762" />
              <circle fill="#004FBD" cx="2169.623" cy="588.08" r="3.764" />
              <circle fill="#004FBD" cx="2157.141" cy="588.08" r="3.765" />
              <circle fill="#004FBD" cx="2144.651" cy="588.08" r="3.765" />
              <circle fill="#004FBD" cx="2132.168" cy="588.08" r="3.765" />
              <circle fill="#004FBD" cx="2119.684" cy="588.08" r="3.765" />
              <circle fill="#004FBD" cx="2107.198" cy="588.08" r="3.763" />
              <circle fill="#004FBD" cx="2094.711" cy="588.08" r="3.765" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2365.626,573.695c0-2.078,1.687-3.764,3.765-3.764c2.079,0,3.765,1.686,3.765,3.764
					c0,2.074-1.686,3.763-3.765,3.763C2367.313,577.458,2365.626,575.771,2365.626,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2353.14,573.695c0-2.078,1.688-3.764,3.765-3.764c2.078,0,3.765,1.686,3.765,3.764
					c0,2.074-1.686,3.763-3.765,3.763C2354.826,577.458,2353.14,575.771,2353.14,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2340.656,573.695c0-2.078,1.684-3.764,3.763-3.764s3.763,1.686,3.763,3.764
					c0,2.074-1.684,3.763-3.763,3.763S2340.656,575.771,2340.656,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2328.17,573.695c0-2.078,1.687-3.764,3.766-3.764c2.076,0,3.764,1.686,3.764,3.764
					c0,2.074-1.688,3.763-3.764,3.763C2329.855,577.458,2328.17,575.771,2328.17,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2315.684,573.695c0-2.078,1.688-3.764,3.767-3.764c2.082,0,3.766,1.686,3.766,3.764
					c0,2.074-1.684,3.763-3.766,3.763C2317.37,577.458,2315.684,575.771,2315.684,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2303.2,573.695c0-2.078,1.684-3.764,3.763-3.764c2.078,0,3.764,1.686,3.764,3.764
					c0,2.074-1.686,3.763-3.764,3.763C2304.884,577.458,2303.2,575.771,2303.2,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2290.714,573.695c0-2.078,1.686-3.764,3.767-3.764c2.077,0,3.763,1.686,3.763,3.764
					c0,2.074-1.686,3.763-3.763,3.763C2292.398,577.458,2290.714,575.771,2290.714,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2278.227,573.695c0-2.078,1.689-3.764,3.77-3.764c2.078,0,3.763,1.686,3.763,3.764
					c0,2.074-1.685,3.763-3.763,3.763C2279.916,577.458,2278.227,575.771,2278.227,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2265.743,573.695c0-2.078,1.685-3.764,3.763-3.764c2.079,0,3.764,1.686,3.764,3.764
					c0,2.074-1.685,3.763-3.764,3.763C2267.428,577.458,2265.743,575.771,2265.743,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2253.258,573.695c0-2.078,1.687-3.764,3.766-3.764c2.078,0,3.763,1.686,3.763,3.764
					c0,2.074-1.685,3.763-3.763,3.763C2254.944,577.458,2253.258,575.771,2253.258,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2240.771,573.695c0-2.078,1.688-3.764,3.763-3.764c2.081,0,3.768,1.686,3.768,3.764
					c0,2.074-1.687,3.763-3.768,3.763C2242.46,577.458,2240.771,575.771,2240.771,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,573.695c0-2.078,1.687-3.764,3.769-3.764c2.076,0,3.764,1.686,3.764,3.764
					c0,2.074-1.688,3.763-3.764,3.763C2229.971,577.458,2228.284,575.771,2228.284,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2215.801,573.695c0-2.078,1.688-3.764,3.767-3.764s3.764,1.686,3.764,3.764
					c0,2.074-1.685,3.763-3.764,3.763S2215.801,575.771,2215.801,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2203.314,573.695c0-2.078,1.686-3.764,3.766-3.764c2.079,0,3.766,1.686,3.766,3.764
					c0,2.074-1.687,3.763-3.766,3.763C2205,577.458,2203.314,575.771,2203.314,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2190.83,573.695c0-2.078,1.688-3.764,3.766-3.764c2.079,0,3.765,1.686,3.765,3.764
					c0,2.074-1.686,3.763-3.765,3.763C2192.518,577.458,2190.83,575.771,2190.83,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2178.348,573.695c0-2.078,1.685-3.764,3.764-3.764c2.078,0,3.763,1.686,3.763,3.764
					c0,2.074-1.686,3.763-3.763,3.763C2180.031,577.458,2178.348,575.771,2178.348,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2165.859,573.695c0-2.078,1.686-3.764,3.766-3.764c2.078,0,3.764,1.686,3.764,3.764
					c0,2.074-1.686,3.763-3.764,3.763C2167.545,577.458,2165.859,575.771,2165.859,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2153.374,573.695c0-2.078,1.685-3.764,3.763-3.764c2.08,0,3.77,1.686,3.77,3.764
					c0,2.074-1.688,3.763-3.77,3.763C2155.059,577.458,2153.374,575.771,2153.374,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2140.887,573.695c0-2.078,1.688-3.764,3.769-3.764c2.079,0,3.764,1.686,3.764,3.764
					c0,2.074-1.685,3.763-3.764,3.763S2140.887,575.771,2140.887,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2128.402,573.695c0-2.078,1.688-3.764,3.766-3.764c2.079,0,3.766,1.686,3.766,3.764
					c0,2.074-1.687,3.763-3.766,3.763S2128.402,575.771,2128.402,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2115.919,573.695c0-2.078,1.685-3.764,3.763-3.764c2.079,0,3.77,1.686,3.77,3.764
					c0,2.074-1.689,3.763-3.77,3.763S2115.919,575.771,2115.919,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2103.436,573.695c0-2.078,1.684-3.764,3.764-3.764c2.076,0,3.763,1.686,3.763,3.764
					c0,2.074-1.687,3.763-3.763,3.763C2105.118,577.458,2103.436,575.771,2103.436,573.695z"
              />
              <path
                fill="#004FBD"
                d="M2090.946,573.695c0-2.078,1.687-3.764,3.767-3.764c2.078,0,3.766,1.686,3.766,3.764
					c0,2.074-1.688,3.763-3.766,3.763C2092.633,577.458,2090.946,575.771,2090.946,573.695z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2353.14,559.313c0-2.082,1.688-3.767,3.765-3.767c2.078,0,3.765,1.685,3.765,3.767
					c0,2.075-1.686,3.761-3.765,3.761C2354.826,563.073,2353.14,561.387,2353.14,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2340.656,559.313c0-2.082,1.684-3.767,3.763-3.767s3.763,1.685,3.763,3.767
					c0,2.075-1.684,3.761-3.763,3.761S2340.656,561.387,2340.656,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2328.17,559.313c0-2.082,1.687-3.767,3.766-3.767c2.076,0,3.764,1.685,3.764,3.767
					c0,2.075-1.688,3.761-3.764,3.761C2329.855,563.073,2328.17,561.387,2328.17,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2315.684,559.313c0-2.082,1.688-3.767,3.767-3.767c2.082,0,3.766,1.685,3.766,3.767
					c0,2.075-1.684,3.761-3.766,3.761C2317.37,563.073,2315.684,561.387,2315.684,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2303.2,559.313c0-2.082,1.684-3.767,3.763-3.767c2.078,0,3.764,1.685,3.764,3.767
					c0,2.075-1.686,3.761-3.764,3.761C2304.884,563.073,2303.2,561.387,2303.2,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2290.714,559.313c0-2.082,1.686-3.767,3.767-3.767c2.077,0,3.763,1.685,3.763,3.767
					c0,2.075-1.686,3.761-3.763,3.761C2292.398,563.073,2290.714,561.387,2290.714,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2278.227,559.313c0-2.082,1.689-3.767,3.77-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.075-1.685,3.761-3.763,3.761C2279.916,563.073,2278.227,561.387,2278.227,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2265.743,559.313c0-2.082,1.685-3.767,3.763-3.767c2.079,0,3.764,1.685,3.764,3.767
					c0,2.075-1.685,3.761-3.764,3.761C2267.428,563.073,2265.743,561.387,2265.743,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2253.258,559.313c0-2.082,1.687-3.767,3.766-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.075-1.685,3.761-3.763,3.761C2254.943,563.073,2253.258,561.387,2253.258,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2240.771,559.313c0-2.082,1.688-3.767,3.763-3.767c2.081,0,3.768,1.685,3.768,3.767
					c0,2.075-1.687,3.761-3.768,3.761C2242.46,563.073,2240.771,561.387,2240.771,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,559.313c0-2.082,1.687-3.767,3.769-3.767c2.076,0,3.764,1.685,3.764,3.767
					c0,2.075-1.688,3.761-3.764,3.761C2229.971,563.073,2228.284,561.387,2228.284,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2215.801,559.313c0-2.082,1.688-3.767,3.767-3.767s3.764,1.685,3.764,3.767
					c0,2.075-1.685,3.761-3.764,3.761S2215.801,561.387,2215.801,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2203.314,559.313c0-2.082,1.686-3.767,3.766-3.767c2.079,0,3.766,1.685,3.766,3.767
					c0,2.075-1.687,3.761-3.766,3.761C2205,563.073,2203.314,561.387,2203.314,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2190.83,559.313c0-2.082,1.688-3.767,3.766-3.767c2.079,0,3.765,1.685,3.765,3.767
					c0,2.075-1.686,3.761-3.765,3.761C2192.518,563.073,2190.83,561.387,2190.83,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2178.348,559.313c0-2.082,1.685-3.767,3.764-3.767c2.078,0,3.763,1.685,3.763,3.767
					c0,2.075-1.686,3.761-3.763,3.761C2180.031,563.073,2178.348,561.387,2178.348,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2165.859,559.313c0-2.082,1.686-3.767,3.766-3.767c2.078,0,3.764,1.685,3.764,3.767
					c0,2.075-1.686,3.761-3.764,3.761C2167.545,563.073,2165.859,561.387,2165.859,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2153.374,559.313c0-2.082,1.685-3.767,3.763-3.767c2.08,0,3.77,1.685,3.77,3.767
					c0,2.075-1.688,3.761-3.77,3.761C2155.059,563.073,2153.374,561.387,2153.374,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2140.887,559.313c0-2.082,1.688-3.767,3.769-3.767c2.079,0,3.764,1.685,3.764,3.767
					c0,2.075-1.685,3.761-3.764,3.761S2140.887,561.387,2140.887,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2128.402,559.313c0-2.082,1.688-3.767,3.766-3.767c2.079,0,3.766,1.685,3.766,3.767
					c0,2.075-1.687,3.761-3.766,3.761S2128.402,561.387,2128.402,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2115.919,559.313c0-2.082,1.685-3.767,3.763-3.767c2.079,0,3.77,1.685,3.77,3.767
					c0,2.075-1.689,3.761-3.77,3.761C2117.604,563.073,2115.919,561.387,2115.919,559.313z"
              />
              <path
                fill="#004FBD"
                d="M2103.436,559.313c0-2.082,1.684-3.767,3.764-3.767c2.076,0,3.763,1.685,3.763,3.767
					c0,2.075-1.687,3.761-3.763,3.761C2105.118,563.073,2103.436,561.387,2103.436,559.313z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2356.904" cy="544.925" r="3.764" />
              <circle fill="#004FBD" cx="2344.419" cy="544.925" r="3.763" />
              <circle fill="#004FBD" cx="2331.936" cy="544.925" r="3.764" />
              <path
                fill="#004FBD"
                d="M2315.684,544.925c0-2.078,1.688-3.763,3.767-3.763c2.082,0,3.766,1.686,3.766,3.763
					s-1.684,3.765-3.766,3.765C2317.37,548.688,2315.684,547.002,2315.684,544.925z"
              />
              <circle fill="#004FBD" cx="2306.963" cy="544.925" r="3.763" />
              <circle fill="#004FBD" cx="2294.479" cy="544.925" r="3.764" />
              <circle fill="#004FBD" cx="2281.992" cy="544.925" r="3.766" />
              <circle fill="#004FBD" cx="2269.506" cy="544.925" r="3.763" />
              <circle fill="#004FBD" cx="2257.021" cy="544.925" r="3.764" />
              <path
                fill="#004FBD"
                d="M2240.771,544.925c0-2.078,1.688-3.763,3.763-3.763c2.081,0,3.768,1.686,3.768,3.763
					s-1.687,3.765-3.768,3.765C2242.46,548.688,2240.771,547.002,2240.771,544.925z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,544.925c0-2.078,1.687-3.763,3.769-3.763c2.076,0,3.764,1.686,3.764,3.763
					s-1.688,3.765-3.764,3.765C2229.971,548.688,2228.284,547.002,2228.284,544.925z"
              />
              <circle fill="#004FBD" cx="2219.564" cy="544.925" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="544.925" r="3.765" />
              <circle fill="#004FBD" cx="2194.596" cy="544.925" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="544.925" r="3.762" />
              <circle fill="#004FBD" cx="2169.623" cy="544.925" r="3.764" />
              <circle fill="#004FBD" cx="2157.141" cy="544.925" r="3.765" />
              <circle fill="#004FBD" cx="2144.651" cy="544.925" r="3.765" />
              <circle fill="#004FBD" cx="2132.168" cy="544.925" r="3.765" />
              <circle fill="#004FBD" cx="2119.684" cy="544.925" r="3.765" />
              <circle fill="#004FBD" cx="2107.198" cy="544.925" r="3.763" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2340.656,530.541c0-2.08,1.684-3.766,3.763-3.766s3.763,1.686,3.763,3.766
					c0,2.075-1.684,3.76-3.763,3.76S2340.656,532.616,2340.656,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2328.17,530.541c0-2.08,1.687-3.766,3.766-3.766c2.076,0,3.764,1.686,3.764,3.766
					c0,2.075-1.688,3.76-3.764,3.76C2329.855,534.301,2328.17,532.616,2328.17,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2315.684,530.541c0-2.08,1.688-3.766,3.767-3.766c2.082,0,3.766,1.686,3.766,3.766
					c0,2.075-1.684,3.76-3.766,3.76C2317.37,534.301,2315.684,532.616,2315.684,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2303.2,530.541c0-2.08,1.684-3.766,3.763-3.766c2.078,0,3.764,1.686,3.764,3.766
					c0,2.075-1.686,3.76-3.764,3.76C2304.884,534.301,2303.2,532.616,2303.2,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2290.714,530.541c0-2.08,1.686-3.766,3.767-3.766c2.077,0,3.763,1.686,3.763,3.766
					c0,2.075-1.686,3.76-3.763,3.76C2292.398,534.301,2290.714,532.616,2290.714,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2278.227,530.541c0-2.08,1.689-3.766,3.77-3.766c2.078,0,3.763,1.686,3.763,3.766
					c0,2.075-1.685,3.76-3.763,3.76C2279.916,534.301,2278.227,532.616,2278.227,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2265.743,530.541c0-2.08,1.685-3.766,3.763-3.766c2.079,0,3.764,1.686,3.764,3.766
					c0,2.075-1.685,3.76-3.764,3.76C2267.428,534.301,2265.743,532.616,2265.743,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2253.258,530.541c0-2.08,1.687-3.766,3.766-3.766c2.078,0,3.763,1.686,3.763,3.766
					c0,2.075-1.685,3.76-3.763,3.76C2254.944,534.301,2253.258,532.616,2253.258,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2240.771,530.541c0-2.08,1.688-3.766,3.763-3.766c2.081,0,3.768,1.686,3.768,3.766
					c0,2.075-1.687,3.76-3.768,3.76C2242.46,534.301,2240.771,532.616,2240.771,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,530.541c0-2.08,1.687-3.766,3.769-3.766c2.076,0,3.764,1.686,3.764,3.766
					c0,2.075-1.688,3.76-3.764,3.76C2229.971,534.301,2228.284,532.616,2228.284,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2215.801,530.541c0-2.08,1.688-3.766,3.767-3.766s3.764,1.686,3.764,3.766
					c0,2.075-1.685,3.76-3.764,3.76S2215.801,532.616,2215.801,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2203.314,530.541c0-2.08,1.686-3.766,3.766-3.766c2.079,0,3.766,1.686,3.766,3.766
					c0,2.075-1.687,3.76-3.766,3.76C2205,534.301,2203.314,532.616,2203.314,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2190.83,530.541c0-2.08,1.688-3.766,3.766-3.766c2.079,0,3.765,1.686,3.765,3.766
					c0,2.075-1.686,3.76-3.765,3.76C2192.518,534.301,2190.83,532.616,2190.83,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2178.348,530.541c0-2.08,1.685-3.766,3.764-3.766c2.078,0,3.763,1.686,3.763,3.766
					c0,2.075-1.686,3.76-3.763,3.76C2180.031,534.301,2178.348,532.616,2178.348,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2165.859,530.541c0-2.08,1.686-3.766,3.766-3.766c2.078,0,3.764,1.686,3.764,3.766
					c0,2.075-1.686,3.76-3.764,3.76C2167.545,534.301,2165.859,532.616,2165.859,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2153.374,530.541c0-2.08,1.685-3.766,3.763-3.766c2.08,0,3.77,1.686,3.77,3.766
					c0,2.075-1.688,3.76-3.77,3.76C2155.059,534.301,2153.374,532.616,2153.374,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2140.887,530.541c0-2.08,1.688-3.766,3.769-3.766c2.079,0,3.764,1.686,3.764,3.766
					c0,2.075-1.685,3.76-3.764,3.76S2140.887,532.616,2140.887,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2128.402,530.541c0-2.08,1.688-3.766,3.766-3.766c2.079,0,3.766,1.686,3.766,3.766
					c0,2.075-1.687,3.76-3.766,3.76S2128.402,532.616,2128.402,530.541z"
              />
              <path
                fill="#004FBD"
                d="M2115.919,530.541c0-2.08,1.685-3.766,3.763-3.766c2.079,0,3.77,1.686,3.77,3.766
					c0,2.075-1.689,3.76-3.77,3.76S2115.919,532.616,2115.919,530.541z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2331.936" cy="516.154" r="3.764" />
              <path
                fill="#004FBD"
                d="M2315.684,516.153c0-2.081,1.688-3.767,3.767-3.767c2.082,0,3.766,1.686,3.766,3.767
					c0,2.079-1.684,3.763-3.766,3.763C2317.37,519.917,2315.684,518.232,2315.684,516.153z"
              />
              <circle fill="#004FBD" cx="2306.963" cy="516.154" r="3.763" />
              <circle fill="#004FBD" cx="2294.479" cy="516.154" r="3.764" />
              <path
                fill="#004FBD"
                d="M2278.227,516.153c0-2.081,1.689-3.767,3.77-3.767c2.078,0,3.763,1.686,3.763,3.767
					c0,2.079-1.685,3.763-3.763,3.763C2279.916,519.917,2278.227,518.232,2278.227,516.153z"
              />
              <circle fill="#004FBD" cx="2269.506" cy="516.154" r="3.763" />
              <circle fill="#004FBD" cx="2257.021" cy="516.154" r="3.764" />
              <path
                fill="#004FBD"
                d="M2240.771,516.153c0-2.081,1.688-3.767,3.763-3.767c2.081,0,3.768,1.686,3.768,3.767
					c0,2.079-1.687,3.763-3.768,3.763C2242.46,519.917,2240.771,518.232,2240.771,516.153z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,516.153c0-2.081,1.687-3.767,3.769-3.767c2.076,0,3.764,1.686,3.764,3.767
					c0,2.079-1.688,3.763-3.764,3.763C2229.971,519.917,2228.284,518.232,2228.284,516.153z"
              />
              <circle fill="#004FBD" cx="2219.564" cy="516.154" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="516.154" r="3.765" />
              <circle fill="#004FBD" cx="2194.596" cy="516.154" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="516.154" r="3.762" />
              <circle fill="#004FBD" cx="2169.623" cy="516.154" r="3.764" />
              <circle fill="#004FBD" cx="2157.141" cy="516.154" r="3.765" />
              <path
                fill="#004FBD"
                d="M2140.887,516.153c0-2.081,1.688-3.767,3.769-3.767c2.079,0,3.764,1.686,3.764,3.767
					c0,2.079-1.685,3.763-3.764,3.763S2140.887,518.232,2140.887,516.153z"
              />
              <circle fill="#004FBD" cx="2132.168" cy="516.154" r="3.765" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M2315.684,501.769c0-2.079,1.688-3.765,3.767-3.765c2.082,0,3.766,1.686,3.766,3.765
					c0,2.076-1.684,3.764-3.766,3.764C2317.37,505.532,2315.684,503.844,2315.684,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2303.2,501.769c0-2.079,1.684-3.765,3.763-3.765c2.078,0,3.764,1.686,3.764,3.765
					c0,2.076-1.686,3.764-3.764,3.764C2304.884,505.532,2303.2,503.844,2303.2,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2290.714,501.769c0-2.079,1.686-3.765,3.767-3.765c2.077,0,3.763,1.686,3.763,3.765
					c0,2.076-1.686,3.764-3.763,3.764C2292.398,505.532,2290.714,503.844,2290.714,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2278.227,501.769c0-2.079,1.689-3.765,3.77-3.765c2.078,0,3.763,1.686,3.763,3.765
					c0,2.076-1.685,3.764-3.763,3.764C2279.916,505.532,2278.227,503.844,2278.227,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2265.743,501.769c0-2.079,1.685-3.765,3.763-3.765c2.079,0,3.764,1.686,3.764,3.765
					c0,2.076-1.685,3.764-3.764,3.764C2267.428,505.532,2265.743,503.844,2265.743,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2253.258,501.769c0-2.079,1.687-3.765,3.766-3.765c2.078,0,3.763,1.686,3.763,3.765
					c0,2.076-1.685,3.764-3.763,3.764C2254.943,505.532,2253.258,503.844,2253.258,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2240.771,501.769c0-2.079,1.688-3.765,3.763-3.765c2.081,0,3.768,1.686,3.768,3.765
					c0,2.076-1.687,3.764-3.768,3.764C2242.46,505.532,2240.771,503.844,2240.771,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,501.769c0-2.079,1.687-3.765,3.769-3.765c2.076,0,3.764,1.686,3.764,3.765
					c0,2.076-1.688,3.764-3.764,3.764C2229.971,505.532,2228.284,503.844,2228.284,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2215.801,501.769c0-2.079,1.688-3.765,3.767-3.765s3.764,1.686,3.764,3.765
					c0,2.076-1.685,3.764-3.764,3.764S2215.801,503.844,2215.801,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2203.314,501.769c0-2.079,1.686-3.765,3.766-3.765c2.079,0,3.766,1.686,3.766,3.765
					c0,2.076-1.687,3.764-3.766,3.764C2205,505.531,2203.314,503.844,2203.314,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2190.83,501.769c0-2.079,1.688-3.765,3.766-3.765c2.079,0,3.765,1.686,3.765,3.765
					c0,2.076-1.686,3.764-3.765,3.764C2192.518,505.532,2190.83,503.844,2190.83,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2178.348,501.769c0-2.079,1.685-3.765,3.764-3.765c2.078,0,3.763,1.686,3.763,3.765
					c0,2.076-1.686,3.764-3.763,3.764C2180.031,505.532,2178.348,503.844,2178.348,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2165.859,501.769c0-2.079,1.686-3.765,3.766-3.765c2.078,0,3.764,1.686,3.764,3.765
					c0,2.076-1.686,3.764-3.764,3.764C2167.545,505.532,2165.859,503.844,2165.859,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2153.374,501.769c0-2.079,1.685-3.765,3.763-3.765c2.08,0,3.77,1.686,3.77,3.765
					c0,2.076-1.688,3.764-3.77,3.764C2155.059,505.532,2153.374,503.844,2153.374,501.769z"
              />
              <path
                fill="#004FBD"
                d="M2140.887,501.769c0-2.079,1.688-3.765,3.769-3.765c2.079,0,3.764,1.686,3.764,3.765
					c0,2.076-1.685,3.764-3.764,3.764S2140.887,503.844,2140.887,501.769z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="2294.479" cy="487.383" r="3.764" />
              <circle fill="#004FBD" cx="2281.992" cy="487.382" r="3.766" />
              <circle fill="#004FBD" cx="2269.506" cy="487.382" r="3.763" />
              <circle fill="#004FBD" cx="2257.021" cy="487.383" r="3.764" />
              <path
                fill="#004FBD"
                d="M2240.771,487.382c0-2.079,1.688-3.763,3.763-3.763c2.081,0,3.768,1.685,3.768,3.763
					s-1.687,3.763-3.768,3.763C2242.46,491.146,2240.771,489.461,2240.771,487.382z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,487.382c0-2.079,1.687-3.763,3.769-3.763c2.076,0,3.764,1.685,3.764,3.763
					s-1.688,3.763-3.764,3.763C2229.971,491.146,2228.284,489.461,2228.284,487.382z"
              />
              <circle fill="#004FBD" cx="2219.564" cy="487.383" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="487.382" r="3.765" />
              <circle fill="#004FBD" cx="2194.596" cy="487.383" r="3.764" />
              <circle fill="#004FBD" cx="2182.11" cy="487.382" r="3.762" />
              <circle fill="#004FBD" cx="2169.623" cy="487.383" r="3.764" />
            </g>
            <g>
              <circle fill="#004FBD" cx="2269.506" cy="472.998" r="3.764" />
              <circle fill="#004FBD" cx="2257.021" cy="472.998" r="3.764" />
              <path
                fill="#004FBD"
                d="M2240.771,472.999c0-2.082,1.688-3.767,3.763-3.767c2.081,0,3.768,1.686,3.768,3.767
					c0,2.078-1.687,3.763-3.768,3.763C2242.46,476.762,2240.771,475.076,2240.771,472.999z"
              />
              <path
                fill="#004FBD"
                d="M2228.284,472.999c0-2.082,1.687-3.767,3.769-3.767c2.076,0,3.764,1.686,3.764,3.767
					c0,2.078-1.688,3.763-3.764,3.763C2229.971,476.762,2228.284,475.076,2228.284,472.999z"
              />
              <circle fill="#004FBD" cx="2219.564" cy="472.998" r="3.764" />
              <circle fill="#004FBD" cx="2207.081" cy="472.998" r="3.765" />
              <circle fill="#004FBD" cx="2194.596" cy="472.998" r="3.764" />
            </g>
          </g>
        </g>
        <g id="path-22" opacity="0.3">
          <g>
            <path
              fill="#004FBD"
              d="M-57.453,361.882c0,2.447-1.979,4.43-4.428,4.43c-2.446,0-4.428-1.981-4.428-4.43
				c0-2.446,1.982-4.428,4.428-4.428C-59.432,357.454-57.453,359.436-57.453,361.882z"
            />
            <circle fill="#004FBD" cx="-47.19" cy="361.882" r="4.43" />
            <circle fill="#004FBD" cx="-32.5" cy="361.882" r="4.427" />
            <path
              fill="#004FBD"
              d="M-13.38,361.882c0,2.447-1.985,4.43-4.431,4.43s-4.428-1.981-4.428-4.43c0-2.446,1.982-4.428,4.428-4.428
				S-13.38,359.436-13.38,361.882z"
            />
            <circle fill="#004FBD" cx="-3.122" cy="361.882" r="4.43" />
            <circle fill="#004FBD" cx="11.566" cy="361.882" r="4.43" />
            <path
              fill="#004FBD"
              d="M30.688,361.882c0,2.447-1.986,4.43-4.431,4.43c-2.448,0-4.428-1.981-4.428-4.43
				c0-2.446,1.981-4.428,4.428-4.428C28.703,357.454,30.688,359.436,30.688,361.882z"
            />
            <circle fill="#004FBD" cx="40.945" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="55.636" cy="361.882" r="4.427" />
            <circle fill="#004FBD" cx="70.325" cy="361.882" r="4.428" />
            <circle fill="#004FBD" cx="85.014" cy="361.882" r="4.43" />
            <circle fill="#004FBD" cx="99.704" cy="361.882" r="4.429" />
            <path
              fill="#004FBD"
              d="M118.822,361.882c0,2.447-1.983,4.43-4.427,4.43c-2.449,0-4.433-1.981-4.433-4.43
				c0-2.446,1.984-4.428,4.433-4.428C116.839,357.454,118.822,359.436,118.822,361.882z"
            />
            <path
              fill="#004FBD"
              d="M133.513,361.882c0,2.447-1.984,4.43-4.433,4.43c-2.442,0-4.427-1.981-4.427-4.43
				c0-2.446,1.985-4.428,4.427-4.428C131.529,357.454,133.513,359.436,133.513,361.882z"
            />
            <circle fill="#004FBD" cx="143.771" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="158.46" cy="361.882" r="4.428" />
            <circle fill="#004FBD" cx="173.15" cy="361.882" r="4.43" />
            <circle fill="#004FBD" cx="187.839" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="202.529" cy="361.882" r="4.428" />
            <circle fill="#004FBD" cx="217.219" cy="361.882" r="4.431" />
            <circle fill="#004FBD" cx="231.908" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="246.597" cy="361.882" r="4.428" />
            <circle fill="#004FBD" cx="261.286" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="275.976" cy="361.882" r="4.429" />
            <circle fill="#004FBD" cx="290.666" cy="361.882" r="4.429" />
          </g>
          <g>
            <g>
              <circle fill="#004FBD" cx="-47.19" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="-32.5" cy="378.807" r="4.427" />
              <circle fill="#004FBD" cx="-17.81" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="-3.122" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="26.259" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="40.945" cy="378.807" r="4.429" />
              <circle fill="#004FBD" cx="55.636" cy="378.807" r="4.427" />
              <circle fill="#004FBD" cx="70.325" cy="378.807" r="4.428" />
              <circle fill="#004FBD" cx="85.014" cy="378.807" r="4.43" />
              <circle fill="#004FBD" cx="99.704" cy="378.807" r="4.429" />
              <path
                fill="#004FBD"
                d="M118.822,378.807c0,2.447-1.983,4.43-4.427,4.43c-2.449,0-4.433-1.982-4.433-4.43
					c0-2.446,1.984-4.43,4.433-4.43C116.839,374.377,118.822,376.36,118.822,378.807z"
              />
              <path
                fill="#004FBD"
                d="M133.513,378.807c0,2.447-1.984,4.43-4.433,4.43c-2.442,0-4.427-1.982-4.427-4.43
					c0-2.446,1.985-4.43,4.427-4.43C131.529,374.377,133.513,376.36,133.513,378.807z"
              />
              <circle fill="#004FBD" cx="143.771" cy="378.807" r="4.429" />
              <circle fill="#004FBD" cx="158.46" cy="378.807" r="4.428" />
              <path
                fill="#004FBD"
                d="M177.581,378.807c0,2.447-1.981,4.43-4.427,4.43c-2.446,0-4.433-1.982-4.433-4.43
					c0-2.446,1.986-4.43,4.433-4.43C175.601,374.377,177.581,376.36,177.581,378.807z"
              />
              <circle fill="#004FBD" cx="187.839" cy="378.807" r="4.429" />
              <circle fill="#004FBD" cx="202.529" cy="378.807" r="4.428" />
              <circle fill="#004FBD" cx="217.219" cy="378.807" r="4.431" />
              <circle fill="#004FBD" cx="231.908" cy="378.807" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="378.807" r="4.428" />
              <circle fill="#004FBD" cx="261.286" cy="378.807" r="4.429" />
              <circle fill="#004FBD" cx="275.976" cy="378.807" r="4.429" />
            </g>
            <g>
              <circle fill="#004FBD" cx="-47.19" cy="395.732" r="4.43" />
              <circle fill="#004FBD" cx="-32.5" cy="395.732" r="4.427" />
              <path
                fill="#004FBD"
                d="M-13.38,395.732c0,2.447-1.985,4.431-4.431,4.431s-4.428-1.983-4.428-4.431
					c0-2.446,1.982-4.43,4.428-4.43S-13.38,393.286-13.38,395.732z"
              />
              <circle fill="#004FBD" cx="-3.122" cy="395.732" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="395.732" r="4.43" />
              <path
                fill="#004FBD"
                d="M30.688,395.732c0,2.447-1.986,4.431-4.431,4.431c-2.448,0-4.428-1.983-4.428-4.431
					c0-2.446,1.981-4.43,4.428-4.43C28.703,391.303,30.688,393.286,30.688,395.732z"
              />
              <circle fill="#004FBD" cx="40.945" cy="395.732" r="4.429" />
              <circle fill="#004FBD" cx="55.636" cy="395.732" r="4.427" />
              <circle fill="#004FBD" cx="70.325" cy="395.732" r="4.428" />
              <circle fill="#004FBD" cx="85.014" cy="395.732" r="4.43" />
              <circle fill="#004FBD" cx="99.704" cy="395.732" r="4.429" />
              <path
                fill="#004FBD"
                d="M118.822,395.732c0,2.447-1.983,4.431-4.427,4.431c-2.449,0-4.433-1.983-4.433-4.431
					c0-2.446,1.984-4.43,4.433-4.43C116.839,391.303,118.822,393.286,118.822,395.732z"
              />
              <path
                fill="#004FBD"
                d="M133.513,395.732c0,2.447-1.984,4.431-4.433,4.431c-2.442,0-4.427-1.983-4.427-4.431
					c0-2.446,1.985-4.43,4.427-4.43C131.529,391.303,133.513,393.286,133.513,395.732z"
              />
              <circle fill="#004FBD" cx="143.771" cy="395.732" r="4.429" />
              <circle fill="#004FBD" cx="158.46" cy="395.732" r="4.428" />
              <circle fill="#004FBD" cx="173.15" cy="395.732" r="4.43" />
              <circle fill="#004FBD" cx="187.839" cy="395.732" r="4.429" />
              <circle fill="#004FBD" cx="202.529" cy="395.732" r="4.428" />
              <circle fill="#004FBD" cx="217.219" cy="395.732" r="4.43" />
              <circle fill="#004FBD" cx="231.908" cy="395.732" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="395.732" r="4.428" />
              <circle fill="#004FBD" cx="261.286" cy="395.732" r="4.429" />
              <circle fill="#004FBD" cx="275.976" cy="395.732" r="4.429" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M-42.761,412.653c0,2.447-1.983,4.432-4.43,4.432c-2.447,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C-44.744,408.225-42.761,410.208-42.761,412.653z"
              />
              <path
                fill="#004FBD"
                d="M-28.072,412.653c0,2.447-1.984,4.432-4.428,4.432c-2.447,0-4.428-1.984-4.428-4.432
					c0-2.445,1.981-4.429,4.428-4.429C-30.056,408.225-28.072,410.208-28.072,412.653z"
              />
              <path
                fill="#004FBD"
                d="M-13.38,412.653c0,2.447-1.985,4.432-4.431,4.432s-4.428-1.984-4.428-4.432
					c0-2.445,1.982-4.429,4.428-4.429S-13.38,410.208-13.38,412.653z"
              />
              <path
                fill="#004FBD"
                d="M1.308,412.653c0,2.447-1.983,4.432-4.43,4.432c-2.447,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C-0.675,408.225,1.308,410.208,1.308,412.653z"
              />
              <path
                fill="#004FBD"
                d="M15.996,412.653c0,2.447-1.98,4.432-4.428,4.432c-2.446,0-4.431-1.984-4.431-4.432
					c0-2.445,1.985-4.429,4.431-4.429C14.015,408.225,15.996,410.208,15.996,412.653z"
              />
              <path
                fill="#004FBD"
                d="M30.688,412.653c0,2.447-1.986,4.432-4.431,4.432c-2.448,0-4.428-1.984-4.428-4.432
					c0-2.445,1.981-4.429,4.428-4.429C28.703,408.225,30.688,410.208,30.688,412.653z"
              />
              <path
                fill="#004FBD"
                d="M45.374,412.653c0,2.447-1.982,4.432-4.427,4.432c-2.447,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C43.392,408.225,45.374,410.208,45.374,412.653z"
              />
              <path
                fill="#004FBD"
                d="M60.064,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.447,0-4.428-1.984-4.428-4.432
					c0-2.445,1.981-4.429,4.428-4.429C58.082,408.225,60.064,410.208,60.064,412.653z"
              />
              <path
                fill="#004FBD"
                d="M74.753,412.653c0,2.447-1.981,4.432-4.426,4.432c-2.447,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C72.772,408.225,74.753,410.208,74.753,412.653z"
              />
              <path
                fill="#004FBD"
                d="M89.444,412.653c0,2.447-1.983,4.432-4.43,4.432c-2.446,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C87.461,408.225,89.444,410.208,89.444,412.653z"
              />
              <path
                fill="#004FBD"
                d="M104.132,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.446,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C102.151,408.225,104.132,410.208,104.132,412.653z"
              />
              <path
                fill="#004FBD"
                d="M118.822,412.653c0,2.447-1.983,4.432-4.427,4.432c-2.449,0-4.433-1.984-4.433-4.432
					c0-2.445,1.984-4.429,4.433-4.429C116.839,408.225,118.822,410.208,118.822,412.653z"
              />
              <path
                fill="#004FBD"
                d="M133.513,412.653c0,2.447-1.984,4.432-4.433,4.432c-2.442,0-4.427-1.984-4.427-4.432
					c0-2.445,1.985-4.429,4.427-4.429C131.529,408.225,133.513,410.208,133.513,412.653z"
              />
              <path
                fill="#004FBD"
                d="M148.2,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.445,0-4.43-1.984-4.43-4.432
					c0-2.445,1.984-4.429,4.43-4.429C146.219,408.225,148.2,410.208,148.2,412.653z"
              />
              <path
                fill="#004FBD"
                d="M162.888,412.653c0,2.447-1.981,4.432-4.428,4.432c-2.446,0-4.428-1.984-4.428-4.432
					c0-2.445,1.981-4.429,4.428-4.429C160.907,408.225,162.888,410.208,162.888,412.653z"
              />
              <path
                fill="#004FBD"
                d="M177.581,412.653c0,2.447-1.981,4.432-4.427,4.432c-2.446,0-4.433-1.984-4.433-4.432
					c0-2.445,1.986-4.429,4.433-4.429C175.601,408.225,177.581,410.208,177.581,412.653z"
              />
              <path
                fill="#004FBD"
                d="M192.269,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.448,0-4.43-1.984-4.43-4.432
					c0-2.445,1.982-4.429,4.43-4.429C190.287,408.225,192.269,410.208,192.269,412.653z"
              />
              <path
                fill="#004FBD"
                d="M206.956,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.446,0-4.428-1.984-4.428-4.432
					c0-2.445,1.982-4.429,4.428-4.429C204.974,408.225,206.956,410.208,206.956,412.653z"
              />
              <path
                fill="#004FBD"
                d="M221.649,412.653c0,2.447-1.982,4.432-4.43,4.432c-2.447,0-4.431-1.984-4.431-4.432
					c0-2.445,1.983-4.429,4.431-4.429C219.667,408.225,221.649,410.208,221.649,412.653z"
              />
              <path
                fill="#004FBD"
                d="M236.337,412.653c0,2.447-1.983,4.432-4.428,4.432c-2.446,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C234.354,408.225,236.337,410.208,236.337,412.653z"
              />
              <path
                fill="#004FBD"
                d="M251.025,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.447,0-4.428-1.984-4.428-4.432
					c0-2.445,1.981-4.429,4.428-4.429C249.043,408.225,251.025,410.208,251.025,412.653z"
              />
              <path
                fill="#004FBD"
                d="M265.714,412.653c0,2.447-1.983,4.432-4.428,4.432c-2.446,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C263.731,408.225,265.714,410.208,265.714,412.653z"
              />
              <path
                fill="#004FBD"
                d="M280.405,412.653c0,2.447-1.982,4.432-4.428,4.432c-2.446,0-4.43-1.984-4.43-4.432
					c0-2.445,1.983-4.429,4.43-4.429C278.424,408.225,280.405,410.208,280.405,412.653z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M-28.072,429.581c0,2.444-1.984,4.425-4.428,4.425c-2.447,0-4.428-1.98-4.428-4.425
					c0-2.451,1.981-4.434,4.428-4.434C-30.056,425.147-28.072,427.13-28.072,429.581z"
              />
              <path
                fill="#004FBD"
                d="M-13.38,429.581c0,2.444-1.985,4.425-4.431,4.425s-4.428-1.98-4.428-4.425
					c0-2.451,1.982-4.434,4.428-4.434S-13.38,427.13-13.38,429.581z"
              />
              <path
                fill="#004FBD"
                d="M1.308,429.581c0,2.444-1.983,4.425-4.43,4.425c-2.447,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C-0.675,425.147,1.308,427.13,1.308,429.581z"
              />
              <path
                fill="#004FBD"
                d="M15.996,429.581c0,2.444-1.98,4.425-4.428,4.425c-2.446,0-4.431-1.98-4.431-4.425
					c0-2.451,1.985-4.434,4.431-4.434C14.015,425.147,15.996,427.13,15.996,429.581z"
              />
              <path
                fill="#004FBD"
                d="M30.688,429.581c0,2.444-1.986,4.425-4.431,4.425c-2.448,0-4.428-1.98-4.428-4.425
					c0-2.451,1.981-4.434,4.428-4.434C28.703,425.147,30.688,427.13,30.688,429.581z"
              />
              <path
                fill="#004FBD"
                d="M45.374,429.581c0,2.444-1.982,4.425-4.427,4.425c-2.447,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C43.392,425.147,45.374,427.13,45.374,429.581z"
              />
              <path
                fill="#004FBD"
                d="M60.064,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.447,0-4.428-1.98-4.428-4.425
					c0-2.451,1.981-4.434,4.428-4.434C58.082,425.147,60.064,427.13,60.064,429.581z"
              />
              <path
                fill="#004FBD"
                d="M74.753,429.581c0,2.444-1.981,4.425-4.426,4.425c-2.447,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C72.772,425.147,74.753,427.13,74.753,429.581z"
              />
              <path
                fill="#004FBD"
                d="M89.444,429.581c0,2.444-1.983,4.425-4.43,4.425c-2.446,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C87.461,425.147,89.444,427.13,89.444,429.581z"
              />
              <path
                fill="#004FBD"
                d="M104.132,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.446,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C102.151,425.147,104.132,427.13,104.132,429.581z"
              />
              <path
                fill="#004FBD"
                d="M118.822,429.581c0,2.444-1.983,4.425-4.427,4.425c-2.449,0-4.433-1.98-4.433-4.425
					c0-2.451,1.984-4.434,4.433-4.434C116.839,425.147,118.822,427.13,118.822,429.581z"
              />
              <path
                fill="#004FBD"
                d="M133.513,429.581c0,2.444-1.984,4.425-4.433,4.425c-2.442,0-4.427-1.98-4.427-4.425
					c0-2.451,1.985-4.434,4.427-4.434C131.529,425.147,133.513,427.13,133.513,429.581z"
              />
              <path
                fill="#004FBD"
                d="M148.2,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.445,0-4.43-1.98-4.43-4.425
					c0-2.451,1.984-4.434,4.43-4.434C146.219,425.147,148.2,427.13,148.2,429.581z"
              />
              <path
                fill="#004FBD"
                d="M162.888,429.581c0,2.444-1.981,4.425-4.428,4.425c-2.446,0-4.428-1.98-4.428-4.425
					c0-2.451,1.981-4.434,4.428-4.434C160.907,425.147,162.888,427.13,162.888,429.581z"
              />
              <path
                fill="#004FBD"
                d="M177.581,429.581c0,2.444-1.981,4.425-4.427,4.425c-2.446,0-4.433-1.98-4.433-4.425
					c0-2.451,1.986-4.434,4.433-4.434C175.601,425.147,177.581,427.13,177.581,429.581z"
              />
              <path
                fill="#004FBD"
                d="M192.269,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.448,0-4.43-1.98-4.43-4.425
					c0-2.451,1.982-4.434,4.43-4.434C190.287,425.147,192.269,427.13,192.269,429.581z"
              />
              <path
                fill="#004FBD"
                d="M206.956,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.446,0-4.428-1.98-4.428-4.425
					c0-2.451,1.982-4.434,4.428-4.434C204.974,425.147,206.956,427.13,206.956,429.581z"
              />
              <path
                fill="#004FBD"
                d="M221.649,429.581c0,2.444-1.982,4.425-4.43,4.425c-2.447,0-4.431-1.98-4.431-4.425
					c0-2.451,1.983-4.434,4.431-4.434C219.667,425.147,221.649,427.13,221.649,429.581z"
              />
              <path
                fill="#004FBD"
                d="M236.337,429.581c0,2.444-1.983,4.425-4.428,4.425c-2.446,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C234.354,425.147,236.337,427.13,236.337,429.581z"
              />
              <path
                fill="#004FBD"
                d="M251.025,429.581c0,2.444-1.982,4.425-4.428,4.425c-2.447,0-4.428-1.98-4.428-4.425
					c0-2.451,1.981-4.434,4.428-4.434C249.043,425.147,251.025,427.13,251.025,429.581z"
              />
              <path
                fill="#004FBD"
                d="M265.714,429.581c0,2.444-1.983,4.425-4.428,4.425c-2.446,0-4.43-1.98-4.43-4.425
					c0-2.451,1.983-4.434,4.43-4.434C263.731,425.147,265.714,427.13,265.714,429.581z"
              />
            </g>
            <g>
              <ellipse
                fill="#004FBD"
                cx="-32.5"
                cy="446.503"
                rx="4.431"
                ry="4.431"
              />
              <path
                fill="#004FBD"
                d="M-13.38,446.503c0,2.446-1.985,4.432-4.431,4.432s-4.428-1.984-4.428-4.432s1.982-4.428,4.428-4.428
					S-13.38,444.057-13.38,446.503z"
              />
              <circle fill="#004FBD" cx="-3.122" cy="446.503" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="446.503" r="4.43" />
              <path
                fill="#004FBD"
                d="M30.688,446.503c0,2.446-1.986,4.432-4.431,4.432c-2.448,0-4.428-1.984-4.428-4.432
					s1.981-4.428,4.428-4.428C28.703,442.075,30.688,444.057,30.688,446.503z"
              />
              <circle fill="#004FBD" cx="40.945" cy="446.503" r="4.429" />
              <ellipse
                fill="#004FBD"
                cx="55.636"
                cy="446.503"
                rx="4.431"
                ry="4.431"
              />
              <circle fill="#004FBD" cx="70.325" cy="446.503" r="4.428" />
              <circle fill="#004FBD" cx="85.014" cy="446.503" r="4.43" />
              <circle fill="#004FBD" cx="99.704" cy="446.503" r="4.429" />
              <path
                fill="#004FBD"
                d="M118.822,446.503c0,2.446-1.983,4.432-4.427,4.432c-2.449,0-4.433-1.984-4.433-4.432
					s1.984-4.428,4.433-4.428C116.839,442.075,118.822,444.057,118.822,446.503z"
              />
              <path
                fill="#004FBD"
                d="M133.513,446.503c0,2.446-1.984,4.432-4.433,4.432c-2.442,0-4.427-1.984-4.427-4.432
					s1.985-4.428,4.427-4.428C131.529,442.075,133.513,444.057,133.513,446.503z"
              />
              <circle fill="#004FBD" cx="143.771" cy="446.503" r="4.429" />
              <circle fill="#004FBD" cx="158.46" cy="446.503" r="4.428" />
              <circle fill="#004FBD" cx="173.15" cy="446.503" r="4.43" />
              <circle fill="#004FBD" cx="187.839" cy="446.503" r="4.429" />
              <circle fill="#004FBD" cx="202.529" cy="446.503" r="4.428" />
              <circle fill="#004FBD" cx="217.219" cy="446.503" r="4.431" />
              <circle fill="#004FBD" cx="231.908" cy="446.503" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="446.503" r="4.428" />
              <circle fill="#004FBD" cx="261.286" cy="446.503" r="4.429" />
            </g>
            <g>
              <circle fill="#004FBD" cx="-17.81" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="-3.122" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="26.259" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="40.945" cy="463.429" r="4.429" />
              <circle fill="#004FBD" cx="55.636" cy="463.429" r="4.427" />
              <circle fill="#004FBD" cx="70.325" cy="463.429" r="4.428" />
              <circle fill="#004FBD" cx="85.014" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="99.704" cy="463.429" r="4.429" />
              <path
                fill="#004FBD"
                d="M118.822,463.429c0,2.446-1.983,4.431-4.427,4.431c-2.449,0-4.433-1.983-4.433-4.431
					s1.984-4.43,4.433-4.43C116.839,459,118.822,460.981,118.822,463.429z"
              />
              <path
                fill="#004FBD"
                d="M133.513,463.429c0,2.446-1.984,4.431-4.433,4.431c-2.442,0-4.427-1.983-4.427-4.431
					s1.985-4.43,4.427-4.43C131.529,459,133.513,460.981,133.513,463.429z"
              />
              <circle fill="#004FBD" cx="143.771" cy="463.429" r="4.429" />
              <circle fill="#004FBD" cx="158.46" cy="463.429" r="4.428" />
              <circle fill="#004FBD" cx="173.15" cy="463.429" r="4.43" />
              <circle fill="#004FBD" cx="187.839" cy="463.429" r="4.429" />
              <circle fill="#004FBD" cx="202.529" cy="463.429" r="4.428" />
              <circle fill="#004FBD" cx="217.219" cy="463.429" r="4.431" />
              <circle fill="#004FBD" cx="231.908" cy="463.429" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="463.429" r="4.428" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M1.308,480.351c0,2.449-1.983,4.435-4.43,4.435c-2.447,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C-0.675,475.927,1.308,477.909,1.308,480.351z"
              />
              <path
                fill="#004FBD"
                d="M15.996,480.351c0,2.449-1.98,4.435-4.428,4.435c-2.446,0-4.431-1.984-4.431-4.435
					c0-2.441,1.985-4.424,4.431-4.424C14.015,475.928,15.996,477.909,15.996,480.351z"
              />
              <path
                fill="#004FBD"
                d="M30.688,480.351c0,2.449-1.986,4.435-4.431,4.435c-2.448,0-4.428-1.984-4.428-4.435
					c0-2.441,1.981-4.424,4.428-4.424C28.703,475.928,30.688,477.909,30.688,480.351z"
              />
              <path
                fill="#004FBD"
                d="M45.374,480.351c0,2.449-1.982,4.435-4.427,4.435c-2.447,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C43.392,475.928,45.374,477.909,45.374,480.351z"
              />
              <path
                fill="#004FBD"
                d="M60.064,480.351c0,2.449-1.982,4.435-4.428,4.435c-2.447,0-4.428-1.984-4.428-4.435
					c0-2.441,1.981-4.424,4.428-4.424C58.082,475.928,60.064,477.909,60.064,480.351z"
              />
              <path
                fill="#004FBD"
                d="M74.753,480.351c0,2.449-1.981,4.435-4.426,4.435c-2.447,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C72.772,475.928,74.753,477.909,74.753,480.351z"
              />
              <path
                fill="#004FBD"
                d="M89.444,480.351c0,2.449-1.983,4.435-4.43,4.435c-2.446,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C87.461,475.927,89.444,477.909,89.444,480.351z"
              />
              <path
                fill="#004FBD"
                d="M104.132,480.351c0,2.449-1.982,4.435-4.428,4.435c-2.446,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C102.151,475.927,104.132,477.909,104.132,480.351z"
              />
              <path
                fill="#004FBD"
                d="M118.822,480.351c0,2.449-1.983,4.435-4.427,4.435c-2.449,0-4.433-1.984-4.433-4.435
					c0-2.441,1.984-4.424,4.433-4.424C116.839,475.928,118.822,477.909,118.822,480.351z"
              />
              <path
                fill="#004FBD"
                d="M133.513,480.351c0,2.449-1.984,4.435-4.433,4.435c-2.442,0-4.427-1.984-4.427-4.435
					c0-2.441,1.985-4.424,4.427-4.424C131.529,475.928,133.513,477.909,133.513,480.351z"
              />
              <path
                fill="#004FBD"
                d="M148.2,480.351c0,2.449-1.982,4.435-4.428,4.435c-2.445,0-4.43-1.984-4.43-4.435
					c0-2.441,1.984-4.424,4.43-4.424C146.219,475.928,148.2,477.909,148.2,480.351z"
              />
              <path
                fill="#004FBD"
                d="M162.888,480.351c0,2.449-1.981,4.435-4.428,4.435c-2.446,0-4.428-1.984-4.428-4.435
					c0-2.441,1.981-4.424,4.428-4.424C160.907,475.927,162.888,477.909,162.888,480.351z"
              />
              <path
                fill="#004FBD"
                d="M177.581,480.351c0,2.449-1.981,4.435-4.427,4.435c-2.446,0-4.433-1.984-4.433-4.435
					c0-2.441,1.986-4.424,4.433-4.424C175.6,475.928,177.581,477.909,177.581,480.351z"
              />
              <path
                fill="#004FBD"
                d="M192.269,480.351c0,2.449-1.982,4.435-4.428,4.435c-2.448,0-4.43-1.984-4.43-4.435
					c0-2.441,1.982-4.424,4.43-4.424C190.287,475.928,192.269,477.909,192.269,480.351z"
              />
              <path
                fill="#004FBD"
                d="M206.956,480.351c0,2.449-1.982,4.435-4.428,4.435c-2.446,0-4.428-1.984-4.428-4.435
					c0-2.441,1.982-4.424,4.428-4.424C204.974,475.927,206.956,477.909,206.956,480.351z"
              />
              <path
                fill="#004FBD"
                d="M221.649,480.351c0,2.449-1.982,4.435-4.43,4.435c-2.447,0-4.431-1.984-4.431-4.435
					c0-2.441,1.983-4.424,4.431-4.424C219.667,475.928,221.649,477.909,221.649,480.351z"
              />
              <path
                fill="#004FBD"
                d="M236.337,480.351c0,2.449-1.983,4.435-4.428,4.435c-2.446,0-4.43-1.984-4.43-4.435
					c0-2.441,1.983-4.424,4.43-4.424C234.354,475.928,236.337,477.909,236.337,480.351z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M15.996,497.279c0,2.444-1.98,4.428-4.428,4.428c-2.446,0-4.431-1.983-4.431-4.428
					c0-2.448,1.985-4.431,4.431-4.431C14.015,492.85,15.996,494.832,15.996,497.279z"
              />
              <path
                fill="#004FBD"
                d="M30.688,497.279c0,2.444-1.986,4.428-4.431,4.428c-2.448,0-4.428-1.983-4.428-4.428
					c0-2.448,1.981-4.431,4.428-4.431C28.703,492.85,30.688,494.832,30.688,497.279z"
              />
              <path
                fill="#004FBD"
                d="M45.374,497.279c0,2.444-1.982,4.428-4.427,4.428c-2.447,0-4.43-1.983-4.43-4.428
					c0-2.448,1.983-4.431,4.43-4.431C43.392,492.85,45.374,494.832,45.374,497.279z"
              />
              <path
                fill="#004FBD"
                d="M60.064,497.279c0,2.444-1.982,4.428-4.428,4.428c-2.447,0-4.428-1.983-4.428-4.428
					c0-2.448,1.981-4.431,4.428-4.431C58.082,492.85,60.064,494.832,60.064,497.279z"
              />
              <path
                fill="#004FBD"
                d="M74.753,497.279c0,2.444-1.981,4.428-4.426,4.428c-2.447,0-4.43-1.983-4.43-4.428
					c0-2.448,1.983-4.431,4.43-4.431C72.772,492.85,74.753,494.832,74.753,497.279z"
              />
              <path
                fill="#004FBD"
                d="M89.444,497.279c0,2.444-1.983,4.428-4.43,4.428c-2.446,0-4.43-1.983-4.43-4.428
					c0-2.448,1.983-4.431,4.43-4.431C87.461,492.85,89.444,494.832,89.444,497.279z"
              />
              <path
                fill="#004FBD"
                d="M104.132,497.279c0,2.444-1.982,4.428-4.428,4.428c-2.446,0-4.43-1.983-4.43-4.428
					c0-2.448,1.983-4.431,4.43-4.431C102.151,492.849,104.132,494.832,104.132,497.279z"
              />
              <path
                fill="#004FBD"
                d="M118.822,497.279c0,2.444-1.983,4.428-4.427,4.428c-2.449,0-4.433-1.983-4.433-4.428
					c0-2.448,1.984-4.431,4.433-4.431C116.839,492.85,118.822,494.832,118.822,497.279z"
              />
              <path
                fill="#004FBD"
                d="M133.513,497.279c0,2.444-1.984,4.428-4.433,4.428c-2.442,0-4.427-1.983-4.427-4.428
					c0-2.448,1.985-4.431,4.427-4.431C131.529,492.85,133.513,494.832,133.513,497.279z"
              />
              <path
                fill="#004FBD"
                d="M148.2,497.279c0,2.444-1.982,4.428-4.428,4.428c-2.445,0-4.43-1.983-4.43-4.428
					c0-2.448,1.984-4.431,4.43-4.431C146.219,492.85,148.2,494.832,148.2,497.279z"
              />
              <path
                fill="#004FBD"
                d="M162.888,497.279c0,2.444-1.981,4.428-4.428,4.428c-2.446,0-4.428-1.983-4.428-4.428
					c0-2.448,1.981-4.431,4.428-4.431C160.907,492.85,162.888,494.832,162.888,497.279z"
              />
              <path
                fill="#004FBD"
                d="M177.581,497.279c0,2.444-1.981,4.428-4.427,4.428c-2.446,0-4.433-1.983-4.433-4.428
					c0-2.448,1.986-4.431,4.433-4.431C175.601,492.849,177.581,494.832,177.581,497.279z"
              />
              <path
                fill="#004FBD"
                d="M192.269,497.279c0,2.444-1.982,4.428-4.428,4.428c-2.448,0-4.43-1.983-4.43-4.428
					c0-2.448,1.982-4.431,4.43-4.431C190.287,492.85,192.269,494.832,192.269,497.279z"
              />
              <path
                fill="#004FBD"
                d="M206.956,497.279c0,2.444-1.982,4.428-4.428,4.428c-2.446,0-4.428-1.983-4.428-4.428
					c0-2.448,1.982-4.431,4.428-4.431C204.974,492.849,206.956,494.832,206.956,497.279z"
              />
              <path
                fill="#004FBD"
                d="M221.649,497.279c0,2.444-1.982,4.428-4.43,4.428c-2.447,0-4.431-1.983-4.431-4.428
					c0-2.448,1.983-4.431,4.431-4.431C219.667,492.85,221.649,494.832,221.649,497.279z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M45.374,514.203c0,2.445-1.982,4.429-4.427,4.429c-2.447,0-4.43-1.982-4.43-4.429
					c0-2.448,1.983-4.43,4.43-4.43C43.392,509.773,45.374,511.754,45.374,514.203z"
              />
              <path
                fill="#004FBD"
                d="M60.064,514.203c0,2.445-1.982,4.429-4.428,4.429c-2.447,0-4.428-1.982-4.428-4.429
					c0-2.448,1.981-4.43,4.428-4.43C58.082,509.773,60.064,511.754,60.064,514.203z"
              />
              <path
                fill="#004FBD"
                d="M74.753,514.203c0,2.445-1.981,4.429-4.426,4.429c-2.447,0-4.43-1.982-4.43-4.429
					c0-2.448,1.983-4.43,4.43-4.43C72.772,509.773,74.753,511.754,74.753,514.203z"
              />
              <path
                fill="#004FBD"
                d="M89.444,514.203c0,2.445-1.983,4.429-4.43,4.429c-2.446,0-4.43-1.982-4.43-4.429
					c0-2.448,1.983-4.43,4.43-4.43C87.461,509.773,89.444,511.754,89.444,514.203z"
              />
              <path
                fill="#004FBD"
                d="M104.132,514.203c0,2.445-1.982,4.429-4.428,4.429c-2.446,0-4.43-1.982-4.43-4.429
					c0-2.448,1.983-4.43,4.43-4.43C102.151,509.773,104.132,511.754,104.132,514.203z"
              />
              <path
                fill="#004FBD"
                d="M118.822,514.203c0,2.445-1.983,4.429-4.427,4.429c-2.449,0-4.433-1.982-4.433-4.429
					c0-2.448,1.984-4.43,4.433-4.43C116.839,509.773,118.822,511.754,118.822,514.203z"
              />
              <path
                fill="#004FBD"
                d="M133.513,514.203c0,2.445-1.984,4.429-4.433,4.429c-2.442,0-4.427-1.982-4.427-4.429
					c0-2.448,1.985-4.43,4.427-4.43C131.529,509.773,133.513,511.754,133.513,514.203z"
              />
              <path
                fill="#004FBD"
                d="M148.2,514.203c0,2.445-1.982,4.429-4.428,4.429c-2.445,0-4.43-1.982-4.43-4.429
					c0-2.448,1.984-4.43,4.43-4.43C146.219,509.773,148.2,511.754,148.2,514.203z"
              />
              <path
                fill="#004FBD"
                d="M162.888,514.203c0,2.445-1.981,4.429-4.428,4.429c-2.446,0-4.428-1.982-4.428-4.429
					c0-2.448,1.981-4.43,4.428-4.43C160.907,509.773,162.888,511.754,162.888,514.203z"
              />
              <path
                fill="#004FBD"
                d="M177.581,514.203c0,2.445-1.981,4.429-4.427,4.429c-2.446,0-4.433-1.982-4.433-4.429
					c0-2.448,1.986-4.43,4.433-4.43C175.601,509.773,177.581,511.754,177.581,514.203z"
              />
              <path
                fill="#004FBD"
                d="M192.269,514.203c0,2.445-1.982,4.429-4.428,4.429c-2.448,0-4.43-1.982-4.43-4.429
					c0-2.448,1.982-4.43,4.43-4.43C190.287,509.773,192.269,511.754,192.269,514.203z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M74.753,531.125c0,2.45-1.981,4.432-4.426,4.432c-2.447,0-4.43-1.98-4.43-4.432
					c0-2.442,1.983-4.426,4.43-4.426C72.772,526.699,74.753,528.683,74.753,531.125z"
              />
              <path
                fill="#004FBD"
                d="M89.444,531.125c0,2.45-1.983,4.432-4.43,4.432c-2.446,0-4.43-1.98-4.43-4.432
					c0-2.442,1.983-4.426,4.43-4.426C87.461,526.7,89.444,528.683,89.444,531.125z"
              />
              <path
                fill="#004FBD"
                d="M104.132,531.125c0,2.45-1.982,4.432-4.428,4.432c-2.446,0-4.43-1.98-4.43-4.432
					c0-2.442,1.983-4.426,4.43-4.426C102.151,526.699,104.132,528.683,104.132,531.125z"
              />
              <path
                fill="#004FBD"
                d="M118.822,531.125c0,2.45-1.983,4.432-4.427,4.432c-2.449,0-4.433-1.98-4.433-4.432
					c0-2.442,1.984-4.426,4.433-4.426C116.839,526.699,118.822,528.683,118.822,531.125z"
              />
              <path
                fill="#004FBD"
                d="M133.513,531.125c0,2.45-1.984,4.432-4.433,4.432c-2.442,0-4.427-1.98-4.427-4.432
					c0-2.442,1.985-4.426,4.427-4.426C131.529,526.699,133.513,528.683,133.513,531.125z"
              />
              <path
                fill="#004FBD"
                d="M148.2,531.125c0,2.45-1.982,4.432-4.428,4.432c-2.445,0-4.43-1.98-4.43-4.432
					c0-2.442,1.984-4.426,4.43-4.426C146.219,526.699,148.2,528.683,148.2,531.125z"
              />
              <path
                fill="#004FBD"
                d="M162.888,531.125c0,2.45-1.981,4.432-4.428,4.432c-2.446,0-4.428-1.98-4.428-4.432
					c0-2.442,1.981-4.426,4.428-4.426C160.907,526.7,162.888,528.683,162.888,531.125z"
              />
            </g>
          </g>
          <g>
            <g>
              <path
                fill="#004FBD"
                d="M271.548,344.962c0-2.448,1.983-4.431,4.43-4.431s4.428,1.981,4.428,4.431
					c0,2.443-1.981,4.425-4.428,4.425S271.548,347.405,271.548,344.962z"
              />
              <path
                fill="#004FBD"
                d="M256.857,344.962c0-2.448,1.983-4.431,4.428-4.431c2.446,0,4.43,1.981,4.43,4.431
					c0,2.443-1.983,4.425-4.43,4.425C258.841,349.387,256.857,347.405,256.857,344.962z"
              />
              <path
                fill="#004FBD"
                d="M242.169,344.962c0-2.448,1.982-4.431,4.428-4.431c2.446,0,4.428,1.981,4.428,4.431
					c0,2.443-1.982,4.425-4.428,4.425C244.151,349.387,242.169,347.405,242.169,344.962z"
              />
              <path
                fill="#004FBD"
                d="M227.479,344.962c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.428,1.981,4.428,4.431
					c0,2.443-1.983,4.425-4.428,4.425C229.463,349.387,227.479,347.405,227.479,344.962z"
              />
              <path
                fill="#004FBD"
                d="M212.789,344.962c0-2.448,1.983-4.431,4.431-4.431c2.448,0,4.43,1.981,4.43,4.431
					c0,2.443-1.982,4.425-4.43,4.425C214.772,349.387,212.789,347.405,212.789,344.962z"
              />
              <path
                fill="#004FBD"
                d="M198.101,344.962c0-2.448,1.981-4.431,4.428-4.431c2.446,0,4.428,1.981,4.428,4.431
					c0,2.443-1.981,4.425-4.428,4.425C200.082,349.387,198.101,347.405,198.101,344.962z"
              />
              <path
                fill="#004FBD"
                d="M183.411,344.962c0-2.448,1.982-4.431,4.43-4.431c2.446,0,4.428,1.981,4.428,4.431
					c0,2.443-1.982,4.425-4.428,4.425C185.393,349.387,183.411,347.405,183.411,344.962z"
              />
              <path
                fill="#004FBD"
                d="M168.721,344.962c0-2.448,1.986-4.431,4.433-4.431c2.446,0,4.427,1.981,4.427,4.431
					c0,2.443-1.981,4.425-4.427,4.425C170.707,349.387,168.721,347.405,168.721,344.962z"
              />
              <path
                fill="#004FBD"
                d="M154.033,344.962c0-2.448,1.981-4.431,4.428-4.431c2.447,0,4.428,1.981,4.428,4.431
					c0,2.443-1.981,4.425-4.428,4.425C156.015,349.387,154.033,347.405,154.033,344.962z"
              />
              <path
                fill="#004FBD"
                d="M139.343,344.962c0-2.448,1.984-4.431,4.43-4.431s4.428,1.981,4.428,4.431
					c0,2.443-1.982,4.425-4.428,4.425S139.343,347.405,139.343,344.962z"
              />
              <path
                fill="#004FBD"
                d="M124.653,344.962c0-2.448,1.985-4.431,4.427-4.431c2.449,0,4.433,1.981,4.433,4.431
					c0,2.443-1.984,4.425-4.433,4.425C126.639,349.387,124.653,347.405,124.653,344.962z"
              />
              <path
                fill="#004FBD"
                d="M109.963,344.962c0-2.448,1.984-4.431,4.433-4.431c2.443,0,4.427,1.981,4.427,4.431
					c0,2.443-1.983,4.425-4.427,4.425C111.946,349.387,109.963,347.405,109.963,344.962z"
              />
              <path
                fill="#004FBD"
                d="M95.274,344.962c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.428,1.981,4.428,4.431
					c0,2.443-1.982,4.425-4.428,4.425C97.258,349.387,95.274,347.405,95.274,344.962z"
              />
              <path
                fill="#004FBD"
                d="M80.584,344.962c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.43,1.981,4.43,4.431
					c0,2.443-1.983,4.425-4.43,4.425C82.568,349.387,80.584,347.405,80.584,344.962z"
              />
              <path
                fill="#004FBD"
                d="M65.897,344.962c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.426,1.981,4.426,4.431
					c0,2.443-1.981,4.425-4.426,4.425C67.88,349.387,65.897,347.405,65.897,344.962z"
              />
              <path
                fill="#004FBD"
                d="M51.209,344.962c0-2.448,1.98-4.431,4.427-4.431c2.446,0,4.428,1.981,4.428,4.431
					c0,2.443-1.982,4.425-4.428,4.425C53.19,349.387,51.209,347.405,51.209,344.962z"
              />
              <path
                fill="#004FBD"
                d="M36.517,344.962c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.427,1.981,4.427,4.431
					c0,2.443-1.982,4.425-4.427,4.425C38.5,349.387,36.517,347.405,36.517,344.962z"
              />
              <path
                fill="#004FBD"
                d="M21.829,344.962c0-2.448,1.98-4.431,4.428-4.431c2.445,0,4.431,1.981,4.431,4.431
					c0,2.443-1.986,4.425-4.431,4.425C23.81,349.387,21.829,347.405,21.829,344.962z"
              />
              <path
                fill="#004FBD"
                d="M7.137,344.962c0-2.448,1.985-4.431,4.431-4.431c2.447,0,4.428,1.981,4.428,4.431
					c0,2.443-1.98,4.425-4.428,4.425C9.122,349.387,7.137,347.405,7.137,344.962z"
              />
              <path
                fill="#004FBD"
                d="M-7.551,344.962c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.43,1.981,4.43,4.431
					c0,2.443-1.983,4.425-4.43,4.425C-5.568,349.387-7.551,347.405-7.551,344.962z"
              />
              <path
                fill="#004FBD"
                d="M-22.24,344.962c0-2.448,1.982-4.431,4.428-4.431s4.431,1.981,4.431,4.431
					c0,2.443-1.985,4.425-4.431,4.425S-22.24,347.405-22.24,344.962z"
              />
              <path
                fill="#004FBD"
                d="M-36.927,344.962c0-2.448,1.981-4.431,4.428-4.431c2.443,0,4.427,1.981,4.427,4.431
					c0,2.443-1.984,4.425-4.427,4.425C-34.947,349.387-36.927,347.405-36.927,344.962z"
              />
              <path
                fill="#004FBD"
                d="M-51.62,344.962c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.43,1.981,4.43,4.431
					c0,2.443-1.983,4.425-4.43,4.425C-49.637,349.387-51.62,347.405-51.62,344.962z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="275.976" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="261.286" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="328.035" r="4.428" />
              <circle fill="#004FBD" cx="231.908" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="217.219" cy="328.035" r="4.431" />
              <circle fill="#004FBD" cx="202.529" cy="328.035" r="4.428" />
              <circle fill="#004FBD" cx="187.839" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="173.15" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="158.46" cy="328.035" r="4.428" />
              <circle fill="#004FBD" cx="143.771" cy="328.035" r="4.429" />
              <path
                fill="#004FBD"
                d="M124.653,328.035c0-2.445,1.985-4.428,4.427-4.428c2.449,0,4.433,1.982,4.433,4.428
					c0,2.446-1.984,4.428-4.433,4.428C126.639,332.463,124.653,330.481,124.653,328.035z"
              />
              <path
                fill="#004FBD"
                d="M109.963,328.035c0-2.445,1.984-4.428,4.433-4.428c2.443,0,4.427,1.982,4.427,4.428
					c0,2.446-1.983,4.428-4.427,4.428C111.946,332.463,109.963,330.481,109.963,328.035z"
              />
              <circle fill="#004FBD" cx="99.704" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="85.014" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="70.325" cy="328.035" r="4.428" />
              <circle fill="#004FBD" cx="55.636" cy="328.035" r="4.427" />
              <circle fill="#004FBD" cx="40.945" cy="328.035" r="4.429" />
              <circle fill="#004FBD" cx="26.259" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="-3.122" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="-17.81" cy="328.035" r="4.43" />
              <circle fill="#004FBD" cx="-32.5" cy="328.035" r="4.427" />
              <circle fill="#004FBD" cx="-47.19" cy="328.035" r="4.43" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M271.548,311.111c0-2.445,1.983-4.427,4.43-4.427s4.428,1.982,4.428,4.427
					c0,2.441-1.981,4.428-4.428,4.428C273.531,315.54,271.548,313.553,271.548,311.111z"
              />
              <path
                fill="#004FBD"
                d="M256.857,311.111c0-2.445,1.983-4.427,4.428-4.427c2.446,0,4.43,1.982,4.43,4.427
					c0,2.441-1.983,4.428-4.43,4.428C258.841,315.539,256.857,313.553,256.857,311.111z"
              />
              <path
                fill="#004FBD"
                d="M242.169,311.111c0-2.445,1.982-4.427,4.428-4.427c2.446,0,4.428,1.982,4.428,4.427
					c0,2.441-1.982,4.428-4.428,4.428C244.151,315.539,242.169,313.553,242.169,311.111z"
              />
              <path
                fill="#004FBD"
                d="M227.479,311.111c0-2.445,1.983-4.427,4.43-4.427c2.445,0,4.428,1.982,4.428,4.427
					c0,2.441-1.983,4.428-4.428,4.428C229.463,315.539,227.479,313.553,227.479,311.111z"
              />
              <path
                fill="#004FBD"
                d="M212.789,311.111c0-2.445,1.983-4.427,4.431-4.427c2.448,0,4.43,1.982,4.43,4.427
					c0,2.441-1.982,4.428-4.43,4.428C214.772,315.539,212.789,313.553,212.789,311.111z"
              />
              <path
                fill="#004FBD"
                d="M198.101,311.111c0-2.445,1.981-4.427,4.428-4.427c2.446,0,4.428,1.982,4.428,4.427
					c0,2.441-1.981,4.428-4.428,4.428C200.082,315.54,198.101,313.553,198.101,311.111z"
              />
              <path
                fill="#004FBD"
                d="M183.411,311.111c0-2.445,1.982-4.427,4.43-4.427c2.446,0,4.428,1.982,4.428,4.427
					c0,2.441-1.982,4.428-4.428,4.428C185.393,315.539,183.411,313.553,183.411,311.111z"
              />
              <path
                fill="#004FBD"
                d="M168.721,311.111c0-2.445,1.986-4.427,4.433-4.427c2.446,0,4.427,1.982,4.427,4.427
					c0,2.441-1.981,4.428-4.427,4.428C170.707,315.539,168.721,313.553,168.721,311.111z"
              />
              <path
                fill="#004FBD"
                d="M154.033,311.111c0-2.445,1.981-4.427,4.428-4.427c2.447,0,4.428,1.982,4.428,4.427
					c0,2.441-1.981,4.428-4.428,4.428C156.015,315.54,154.033,313.553,154.033,311.111z"
              />
              <path
                fill="#004FBD"
                d="M139.343,311.111c0-2.445,1.984-4.427,4.43-4.427s4.428,1.982,4.428,4.427
					c0,2.441-1.982,4.428-4.428,4.428S139.343,313.553,139.343,311.111z"
              />
              <path
                fill="#004FBD"
                d="M124.653,311.111c0-2.445,1.985-4.427,4.427-4.427c2.449,0,4.433,1.982,4.433,4.427
					c0,2.441-1.984,4.428-4.433,4.428C126.639,315.539,124.653,313.553,124.653,311.111z"
              />
              <path
                fill="#004FBD"
                d="M109.963,311.111c0-2.445,1.984-4.427,4.433-4.427c2.443,0,4.427,1.982,4.427,4.427
					c0,2.441-1.983,4.428-4.427,4.428C111.946,315.539,109.963,313.553,109.963,311.111z"
              />
              <path
                fill="#004FBD"
                d="M95.274,311.111c0-2.445,1.983-4.427,4.43-4.427c2.447,0,4.428,1.982,4.428,4.427
					c0,2.441-1.982,4.428-4.428,4.428C97.258,315.539,95.274,313.553,95.274,311.111z"
              />
              <path
                fill="#004FBD"
                d="M80.584,311.111c0-2.445,1.983-4.427,4.43-4.427c2.447,0,4.43,1.982,4.43,4.427
					c0,2.441-1.983,4.428-4.43,4.428C82.568,315.54,80.584,313.553,80.584,311.111z"
              />
              <path
                fill="#004FBD"
                d="M65.897,311.111c0-2.445,1.983-4.427,4.43-4.427c2.445,0,4.426,1.982,4.426,4.427
					c0,2.441-1.981,4.428-4.426,4.428C67.88,315.539,65.897,313.553,65.897,311.111z"
              />
              <path
                fill="#004FBD"
                d="M51.209,311.111c0-2.445,1.98-4.427,4.427-4.427c2.446,0,4.428,1.982,4.428,4.427
					c0,2.441-1.982,4.428-4.428,4.428C53.19,315.539,51.209,313.553,51.209,311.111z"
              />
              <path
                fill="#004FBD"
                d="M36.517,311.111c0-2.445,1.983-4.427,4.43-4.427c2.445,0,4.427,1.982,4.427,4.427
					c0,2.441-1.982,4.428-4.427,4.428C38.5,315.539,36.517,313.553,36.517,311.111z"
              />
              <path
                fill="#004FBD"
                d="M21.829,311.111c0-2.445,1.98-4.427,4.428-4.427c2.445,0,4.431,1.982,4.431,4.427
					c0,2.441-1.986,4.428-4.431,4.428C23.81,315.539,21.829,313.553,21.829,311.111z"
              />
              <path
                fill="#004FBD"
                d="M7.137,311.111c0-2.445,1.985-4.427,4.431-4.427c2.447,0,4.428,1.982,4.428,4.427
					c0,2.441-1.98,4.428-4.428,4.428C9.122,315.539,7.137,313.553,7.137,311.111z"
              />
              <path
                fill="#004FBD"
                d="M-7.551,311.111c0-2.445,1.983-4.427,4.43-4.427c2.447,0,4.43,1.982,4.43,4.427
					c0,2.441-1.983,4.428-4.43,4.428C-5.568,315.539-7.551,313.553-7.551,311.111z"
              />
              <path
                fill="#004FBD"
                d="M-22.24,311.111c0-2.445,1.982-4.427,4.428-4.427s4.431,1.982,4.431,4.427
					c0,2.441-1.985,4.428-4.431,4.428S-22.24,313.553-22.24,311.111z"
              />
              <path
                fill="#004FBD"
                d="M-36.927,311.111c0-2.445,1.981-4.427,4.428-4.427c2.443,0,4.427,1.982,4.427,4.427
					c0,2.441-1.984,4.428-4.427,4.428C-34.947,315.539-36.927,313.553-36.927,311.111z"
              />
              <path
                fill="#004FBD"
                d="M-51.62,311.111c0-2.445,1.983-4.427,4.43-4.427c2.447,0,4.43,1.982,4.43,4.427
					c0,2.441-1.983,4.428-4.43,4.428C-49.637,315.539-51.62,313.553-51.62,311.111z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M256.857,294.188c0-2.448,1.983-4.43,4.428-4.43c2.446,0,4.43,1.983,4.43,4.43
					c0,2.442-1.983,4.426-4.43,4.426C258.841,298.614,256.857,296.63,256.857,294.188z"
              />
              <path
                fill="#004FBD"
                d="M242.169,294.188c0-2.448,1.982-4.43,4.428-4.43c2.446,0,4.428,1.983,4.428,4.43
					c0,2.442-1.982,4.426-4.428,4.426C244.151,298.614,242.169,296.63,242.169,294.188z"
              />
              <path
                fill="#004FBD"
                d="M227.479,294.188c0-2.448,1.983-4.43,4.43-4.43c2.445,0,4.428,1.983,4.428,4.43
					c0,2.442-1.983,4.426-4.428,4.426C229.463,298.614,227.479,296.63,227.479,294.188z"
              />
              <path
                fill="#004FBD"
                d="M212.789,294.188c0-2.448,1.983-4.43,4.431-4.43c2.448,0,4.43,1.983,4.43,4.43
					c0,2.442-1.982,4.426-4.43,4.426C214.772,298.614,212.789,296.63,212.789,294.188z"
              />
              <path
                fill="#004FBD"
                d="M198.101,294.188c0-2.448,1.981-4.43,4.428-4.43c2.446,0,4.428,1.983,4.428,4.43
					c0,2.442-1.981,4.426-4.428,4.426C200.082,298.613,198.101,296.63,198.101,294.188z"
              />
              <path
                fill="#004FBD"
                d="M183.411,294.188c0-2.448,1.982-4.43,4.43-4.43c2.446,0,4.428,1.983,4.428,4.43
					c0,2.442-1.982,4.426-4.428,4.426C185.393,298.614,183.411,296.63,183.411,294.188z"
              />
              <path
                fill="#004FBD"
                d="M168.721,294.188c0-2.448,1.986-4.43,4.433-4.43c2.446,0,4.427,1.983,4.427,4.43
					c0,2.442-1.981,4.426-4.427,4.426C170.707,298.614,168.721,296.63,168.721,294.188z"
              />
              <path
                fill="#004FBD"
                d="M154.033,294.188c0-2.448,1.981-4.43,4.428-4.43c2.447,0,4.428,1.983,4.428,4.43
					c0,2.442-1.981,4.426-4.428,4.426C156.015,298.613,154.033,296.63,154.033,294.188z"
              />
              <path
                fill="#004FBD"
                d="M139.343,294.188c0-2.448,1.984-4.43,4.43-4.43s4.428,1.983,4.428,4.43c0,2.442-1.982,4.426-4.428,4.426
					C141.327,298.614,139.343,296.63,139.343,294.188z"
              />
              <path
                fill="#004FBD"
                d="M124.653,294.188c0-2.448,1.985-4.43,4.427-4.43c2.449,0,4.433,1.983,4.433,4.43
					c0,2.442-1.984,4.426-4.433,4.426C126.639,298.614,124.653,296.63,124.653,294.188z"
              />
              <path
                fill="#004FBD"
                d="M109.963,294.188c0-2.448,1.984-4.43,4.433-4.43c2.443,0,4.427,1.983,4.427,4.43
					c0,2.442-1.983,4.426-4.427,4.426C111.946,298.614,109.963,296.63,109.963,294.188z"
              />
              <path
                fill="#004FBD"
                d="M95.274,294.188c0-2.448,1.983-4.43,4.43-4.43c2.447,0,4.428,1.983,4.428,4.43
					c0,2.442-1.982,4.426-4.428,4.426C97.258,298.614,95.274,296.63,95.274,294.188z"
              />
              <path
                fill="#004FBD"
                d="M80.584,294.188c0-2.448,1.983-4.43,4.43-4.43c2.447,0,4.43,1.983,4.43,4.43
					c0,2.442-1.983,4.426-4.43,4.426C82.568,298.613,80.584,296.63,80.584,294.188z"
              />
              <path
                fill="#004FBD"
                d="M65.897,294.188c0-2.448,1.983-4.43,4.43-4.43c2.445,0,4.426,1.983,4.426,4.43
					c0,2.442-1.981,4.426-4.426,4.426C67.88,298.614,65.897,296.63,65.897,294.188z"
              />
              <path
                fill="#004FBD"
                d="M51.209,294.188c0-2.448,1.98-4.43,4.427-4.43c2.446,0,4.428,1.983,4.428,4.43
					c0,2.442-1.982,4.426-4.428,4.426C53.19,298.614,51.209,296.63,51.209,294.188z"
              />
              <path
                fill="#004FBD"
                d="M36.517,294.188c0-2.448,1.983-4.43,4.43-4.43c2.445,0,4.427,1.983,4.427,4.43
					c0,2.442-1.982,4.426-4.427,4.426C38.5,298.614,36.517,296.63,36.517,294.188z"
              />
              <path
                fill="#004FBD"
                d="M21.829,294.188c0-2.448,1.98-4.43,4.428-4.43c2.445,0,4.431,1.983,4.431,4.43
					c0,2.442-1.986,4.426-4.431,4.426C23.81,298.614,21.829,296.63,21.829,294.188z"
              />
              <path
                fill="#004FBD"
                d="M7.137,294.188c0-2.448,1.985-4.43,4.431-4.43c2.447,0,4.428,1.983,4.428,4.43
					c0,2.442-1.98,4.426-4.428,4.426C9.122,298.614,7.137,296.63,7.137,294.188z"
              />
              <path
                fill="#004FBD"
                d="M-7.551,294.188c0-2.448,1.983-4.43,4.43-4.43c2.447,0,4.43,1.983,4.43,4.43
					c0,2.442-1.983,4.426-4.43,4.426C-5.568,298.614-7.551,296.63-7.551,294.188z"
              />
              <path
                fill="#004FBD"
                d="M-22.24,294.188c0-2.448,1.982-4.43,4.428-4.43s4.431,1.983,4.431,4.43c0,2.442-1.985,4.426-4.431,4.426
					S-22.24,296.63-22.24,294.188z"
              />
              <path
                fill="#004FBD"
                d="M-36.927,294.188c0-2.448,1.981-4.43,4.428-4.43c2.443,0,4.427,1.983,4.427,4.43
					c0,2.442-1.984,4.426-4.427,4.426C-34.947,298.614-36.927,296.63-36.927,294.188z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="261.286" cy="277.261" r="4.429" />
              <circle fill="#004FBD" cx="246.597" cy="277.262" r="4.428" />
              <circle fill="#004FBD" cx="231.908" cy="277.261" r="4.429" />
              <circle fill="#004FBD" cx="217.219" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="202.529" cy="277.262" r="4.428" />
              <circle fill="#004FBD" cx="187.839" cy="277.261" r="4.429" />
              <circle fill="#004FBD" cx="173.15" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="158.46" cy="277.262" r="4.428" />
              <circle fill="#004FBD" cx="143.771" cy="277.261" r="4.429" />
              <path
                fill="#004FBD"
                d="M124.653,277.262c0-2.446,1.985-4.428,4.427-4.428c2.449,0,4.433,1.982,4.433,4.428
					c0,2.443-1.984,4.429-4.433,4.429C126.639,281.69,124.653,279.705,124.653,277.262z"
              />
              <path
                fill="#004FBD"
                d="M109.963,277.262c0-2.446,1.984-4.428,4.433-4.428c2.443,0,4.427,1.982,4.427,4.428
					c0,2.443-1.983,4.429-4.427,4.429C111.946,281.69,109.963,279.705,109.963,277.262z"
              />
              <circle fill="#004FBD" cx="99.704" cy="277.261" r="4.429" />
              <circle fill="#004FBD" cx="85.014" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="70.325" cy="277.262" r="4.428" />
              <circle fill="#004FBD" cx="55.636" cy="277.261" r="4.427" />
              <circle fill="#004FBD" cx="40.945" cy="277.261" r="4.429" />
              <circle fill="#004FBD" cx="26.259" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="11.566" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="-3.122" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="-17.81" cy="277.261" r="4.43" />
              <circle fill="#004FBD" cx="-32.5" cy="277.261" r="4.427" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M242.169,260.339c0-2.448,1.982-4.431,4.428-4.431c2.446,0,4.428,1.983,4.428,4.431
					c0,2.442-1.982,4.424-4.428,4.424C244.151,264.763,242.169,262.781,242.169,260.339z"
              />
              <path
                fill="#004FBD"
                d="M227.479,260.339c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.428,1.983,4.428,4.431
					c0,2.442-1.983,4.424-4.428,4.424C229.463,264.763,227.479,262.781,227.479,260.339z"
              />
              <path
                fill="#004FBD"
                d="M212.789,260.339c0-2.448,1.983-4.431,4.431-4.431c2.448,0,4.43,1.983,4.43,4.431
					c0,2.442-1.982,4.424-4.43,4.424C214.772,264.763,212.789,262.781,212.789,260.339z"
              />
              <path
                fill="#004FBD"
                d="M198.101,260.339c0-2.448,1.981-4.431,4.428-4.431c2.446,0,4.428,1.983,4.428,4.431
					c0,2.442-1.981,4.424-4.428,4.424C200.082,264.763,198.101,262.781,198.101,260.339z"
              />
              <path
                fill="#004FBD"
                d="M183.411,260.339c0-2.448,1.982-4.431,4.43-4.431c2.446,0,4.428,1.983,4.428,4.431
					c0,2.442-1.982,4.424-4.428,4.424C185.393,264.763,183.411,262.781,183.411,260.339z"
              />
              <path
                fill="#004FBD"
                d="M168.721,260.339c0-2.448,1.986-4.431,4.433-4.431c2.446,0,4.427,1.983,4.427,4.431
					c0,2.442-1.981,4.424-4.427,4.424C170.707,264.763,168.721,262.781,168.721,260.339z"
              />
              <path
                fill="#004FBD"
                d="M154.033,260.339c0-2.448,1.981-4.431,4.428-4.431c2.447,0,4.428,1.983,4.428,4.431
					c0,2.442-1.981,4.424-4.428,4.424C156.015,264.763,154.033,262.781,154.033,260.339z"
              />
              <path
                fill="#004FBD"
                d="M139.343,260.339c0-2.448,1.984-4.431,4.43-4.431s4.428,1.983,4.428,4.431
					c0,2.442-1.982,4.424-4.428,4.424S139.343,262.781,139.343,260.339z"
              />
              <path
                fill="#004FBD"
                d="M124.653,260.339c0-2.448,1.985-4.431,4.427-4.431c2.449,0,4.433,1.983,4.433,4.431
					c0,2.442-1.984,4.424-4.433,4.424C126.639,264.763,124.653,262.781,124.653,260.339z"
              />
              <path
                fill="#004FBD"
                d="M109.963,260.339c0-2.448,1.984-4.431,4.433-4.431c2.443,0,4.427,1.983,4.427,4.431
					c0,2.442-1.983,4.424-4.427,4.424C111.946,264.763,109.963,262.781,109.963,260.339z"
              />
              <path
                fill="#004FBD"
                d="M95.274,260.339c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.428,1.983,4.428,4.431
					c0,2.442-1.982,4.424-4.428,4.424C97.258,264.763,95.274,262.781,95.274,260.339z"
              />
              <path
                fill="#004FBD"
                d="M80.584,260.339c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.43,1.983,4.43,4.431
					c0,2.442-1.983,4.424-4.43,4.424C82.568,264.763,80.584,262.781,80.584,260.339z"
              />
              <path
                fill="#004FBD"
                d="M65.897,260.339c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.426,1.983,4.426,4.431
					c0,2.442-1.981,4.424-4.426,4.424C67.88,264.763,65.897,262.781,65.897,260.339z"
              />
              <path
                fill="#004FBD"
                d="M51.209,260.339c0-2.448,1.98-4.431,4.427-4.431c2.446,0,4.428,1.983,4.428,4.431
					c0,2.442-1.982,4.424-4.428,4.424C53.19,264.763,51.209,262.781,51.209,260.339z"
              />
              <path
                fill="#004FBD"
                d="M36.517,260.339c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.427,1.983,4.427,4.431
					c0,2.442-1.982,4.424-4.427,4.424C38.5,264.763,36.517,262.781,36.517,260.339z"
              />
              <path
                fill="#004FBD"
                d="M21.829,260.339c0-2.448,1.98-4.431,4.428-4.431c2.445,0,4.431,1.983,4.431,4.431
					c0,2.442-1.986,4.424-4.431,4.424C23.81,264.763,21.829,262.781,21.829,260.339z"
              />
              <path
                fill="#004FBD"
                d="M7.137,260.339c0-2.448,1.985-4.431,4.431-4.431c2.447,0,4.428,1.983,4.428,4.431
					c0,2.442-1.98,4.424-4.428,4.424C9.122,264.763,7.137,262.781,7.137,260.339z"
              />
              <path
                fill="#004FBD"
                d="M-7.551,260.339c0-2.448,1.983-4.431,4.43-4.431c2.447,0,4.43,1.983,4.43,4.431
					c0,2.442-1.983,4.424-4.43,4.424C-5.568,264.763-7.551,262.781-7.551,260.339z"
              />
              <path
                fill="#004FBD"
                d="M-22.24,260.339c0-2.448,1.982-4.431,4.428-4.431s4.431,1.983,4.431,4.431
					c0,2.442-1.985,4.424-4.431,4.424S-22.24,262.781-22.24,260.339z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="231.908" cy="243.412" r="4.429" />
              <circle fill="#004FBD" cx="217.219" cy="243.412" r="4.43" />
              <circle fill="#004FBD" cx="202.529" cy="243.412" r="4.428" />
              <circle fill="#004FBD" cx="187.839" cy="243.412" r="4.429" />
              <path
                fill="#004FBD"
                d="M168.721,243.412c0-2.448,1.986-4.431,4.433-4.431c2.446,0,4.427,1.983,4.427,4.431
					c0,2.445-1.981,4.428-4.427,4.428C170.707,247.839,168.721,245.857,168.721,243.412z"
              />
              <circle fill="#004FBD" cx="158.46" cy="243.412" r="4.428" />
              <circle fill="#004FBD" cx="143.771" cy="243.412" r="4.429" />
              <path
                fill="#004FBD"
                d="M124.653,243.412c0-2.448,1.985-4.431,4.427-4.431c2.449,0,4.433,1.983,4.433,4.431
					c0,2.445-1.984,4.428-4.433,4.428C126.639,247.839,124.653,245.857,124.653,243.412z"
              />
              <path
                fill="#004FBD"
                d="M109.963,243.412c0-2.448,1.984-4.431,4.433-4.431c2.443,0,4.427,1.983,4.427,4.431
					c0,2.445-1.983,4.428-4.427,4.428C111.946,247.839,109.963,245.857,109.963,243.412z"
              />
              <circle fill="#004FBD" cx="99.704" cy="243.412" r="4.429" />
              <circle fill="#004FBD" cx="85.014" cy="243.412" r="4.43" />
              <path
                fill="#004FBD"
                d="M65.897,243.412c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.426,1.983,4.426,4.431
					c0,2.445-1.981,4.428-4.426,4.428C67.88,247.839,65.897,245.857,65.897,243.412z"
              />
              <circle fill="#004FBD" cx="55.636" cy="243.412" r="4.427" />
              <circle fill="#004FBD" cx="40.945" cy="243.412" r="4.429" />
              <circle fill="#004FBD" cx="26.259" cy="243.412" r="4.43" />
              <path
                fill="#004FBD"
                d="M7.137,243.412c0-2.448,1.985-4.431,4.431-4.431c2.447,0,4.428,1.983,4.428,4.431
					c0,2.445-1.98,4.428-4.428,4.428C9.122,247.839,7.137,245.857,7.137,243.412z"
              />
              <circle fill="#004FBD" cx="-3.122" cy="243.412" r="4.43" />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M212.789,226.488c0-2.448,1.983-4.43,4.431-4.43c2.448,0,4.43,1.982,4.43,4.43
					c0,2.442-1.982,4.428-4.43,4.428C214.772,230.916,212.789,228.93,212.789,226.488z"
              />
              <path
                fill="#004FBD"
                d="M198.101,226.488c0-2.448,1.981-4.43,4.428-4.43c2.446,0,4.428,1.982,4.428,4.43
					c0,2.442-1.981,4.428-4.428,4.428C200.082,230.916,198.101,228.93,198.101,226.488z"
              />
              <path
                fill="#004FBD"
                d="M183.411,226.488c0-2.448,1.982-4.43,4.43-4.43c2.446,0,4.428,1.982,4.428,4.43
					c0,2.442-1.982,4.428-4.428,4.428C185.393,230.916,183.411,228.93,183.411,226.488z"
              />
              <path
                fill="#004FBD"
                d="M168.721,226.488c0-2.448,1.986-4.43,4.433-4.43c2.446,0,4.427,1.982,4.427,4.43
					c0,2.442-1.981,4.428-4.427,4.428C170.707,230.916,168.721,228.93,168.721,226.488z"
              />
              <path
                fill="#004FBD"
                d="M154.033,226.488c0-2.448,1.981-4.43,4.428-4.43c2.447,0,4.428,1.982,4.428,4.43
					c0,2.442-1.981,4.428-4.428,4.428C156.015,230.916,154.033,228.93,154.033,226.488z"
              />
              <path
                fill="#004FBD"
                d="M139.343,226.488c0-2.448,1.984-4.43,4.43-4.43s4.428,1.982,4.428,4.43c0,2.442-1.982,4.428-4.428,4.428
					S139.343,228.93,139.343,226.488z"
              />
              <path
                fill="#004FBD"
                d="M124.653,226.488c0-2.448,1.985-4.43,4.427-4.43c2.449,0,4.433,1.982,4.433,4.43
					c0,2.442-1.984,4.428-4.433,4.428C126.639,230.916,124.653,228.93,124.653,226.488z"
              />
              <path
                fill="#004FBD"
                d="M109.963,226.488c0-2.448,1.984-4.43,4.433-4.43c2.443,0,4.427,1.982,4.427,4.43
					c0,2.442-1.983,4.428-4.427,4.428C111.946,230.916,109.963,228.93,109.963,226.488z"
              />
              <path
                fill="#004FBD"
                d="M95.274,226.488c0-2.448,1.983-4.43,4.43-4.43c2.447,0,4.428,1.982,4.428,4.43
					c0,2.442-1.982,4.428-4.428,4.428C97.258,230.916,95.274,228.93,95.274,226.488z"
              />
              <path
                fill="#004FBD"
                d="M80.584,226.488c0-2.448,1.983-4.43,4.43-4.43c2.447,0,4.43,1.982,4.43,4.43
					c0,2.442-1.983,4.428-4.43,4.428C82.568,230.916,80.584,228.93,80.584,226.488z"
              />
              <path
                fill="#004FBD"
                d="M65.897,226.488c0-2.448,1.983-4.43,4.43-4.43c2.445,0,4.426,1.982,4.426,4.43
					c0,2.442-1.981,4.428-4.426,4.428C67.88,230.916,65.897,228.93,65.897,226.488z"
              />
              <path
                fill="#004FBD"
                d="M51.209,226.488c0-2.448,1.98-4.43,4.427-4.43c2.446,0,4.428,1.982,4.428,4.43
					c0,2.442-1.982,4.428-4.428,4.428C53.19,230.916,51.209,228.93,51.209,226.488z"
              />
              <path
                fill="#004FBD"
                d="M36.517,226.488c0-2.448,1.983-4.43,4.43-4.43c2.445,0,4.427,1.982,4.427,4.43
					c0,2.442-1.982,4.428-4.427,4.428C38.5,230.916,36.517,228.93,36.517,226.488z"
              />
              <path
                fill="#004FBD"
                d="M21.829,226.488c0-2.448,1.98-4.43,4.428-4.43c2.445,0,4.431,1.982,4.431,4.43
					c0,2.442-1.986,4.428-4.431,4.428C23.81,230.916,21.829,228.93,21.829,226.488z"
              />
              <path
                fill="#004FBD"
                d="M7.137,226.488c0-2.448,1.985-4.43,4.431-4.43c2.447,0,4.428,1.982,4.428,4.43
					c0,2.442-1.98,4.428-4.428,4.428C9.122,230.916,7.137,228.93,7.137,226.488z"
              />
            </g>
            <g>
              <circle fill="#004FBD" cx="187.839" cy="209.562" r="4.429" />
              <circle fill="#004FBD" cx="173.15" cy="209.562" r="4.43" />
              <circle fill="#004FBD" cx="158.46" cy="209.562" r="4.428" />
              <circle fill="#004FBD" cx="143.771" cy="209.562" r="4.429" />
              <path
                fill="#004FBD"
                d="M124.653,209.562c0-2.446,1.985-4.428,4.427-4.428c2.449,0,4.433,1.982,4.433,4.428
					c0,2.445-1.984,4.428-4.433,4.428C126.639,213.99,124.653,212.007,124.653,209.562z"
              />
              <path
                fill="#004FBD"
                d="M109.963,209.562c0-2.446,1.984-4.428,4.433-4.428c2.443,0,4.427,1.982,4.427,4.428
					c0,2.445-1.983,4.428-4.427,4.428C111.946,213.99,109.963,212.007,109.963,209.562z"
              />
              <circle fill="#004FBD" cx="99.704" cy="209.562" r="4.429" />
              <circle fill="#004FBD" cx="85.014" cy="209.562" r="4.43" />
              <circle fill="#004FBD" cx="70.325" cy="209.562" r="4.428" />
              <circle fill="#004FBD" cx="55.636" cy="209.562" r="4.427" />
              <circle fill="#004FBD" cx="40.945" cy="209.562" r="4.429" />
            </g>
            <g>
              <circle fill="#004FBD" cx="158.46" cy="192.639" r="4.428" />
              <circle fill="#004FBD" cx="143.771" cy="192.639" r="4.429" />
              <path
                fill="#004FBD"
                d="M124.653,192.639c0-2.448,1.985-4.431,4.427-4.431c2.449,0,4.433,1.982,4.433,4.431
					c0,2.445-1.984,4.428-4.433,4.428C126.639,197.066,124.653,195.083,124.653,192.639z"
              />
              <path
                fill="#004FBD"
                d="M109.963,192.639c0-2.448,1.984-4.431,4.433-4.431c2.443,0,4.427,1.982,4.427,4.431
					c0,2.445-1.983,4.428-4.427,4.428C111.946,197.066,109.963,195.083,109.963,192.639z"
              />
              <circle fill="#004FBD" cx="99.704" cy="192.639" r="4.429" />
              <circle fill="#004FBD" cx="85.014" cy="192.639" r="4.43" />
              <path
                fill="#004FBD"
                d="M65.897,192.639c0-2.448,1.983-4.431,4.43-4.431c2.445,0,4.426,1.982,4.426,4.431
					c0,2.445-1.981,4.428-4.426,4.428C67.88,197.066,65.897,195.083,65.897,192.639z"
              />
            </g>
          </g>
        </g>
        <g id="path-21">
          <path
            fill="#39B1A7"
            d="M2232.053,913.257c-39.995,0-78.816-7.843-115.39-23.311c-35.302-14.933-66.995-36.298-94.203-63.505
			c-27.207-27.206-48.573-58.901-63.503-94.202c-15.47-36.572-23.313-75.396-23.313-115.391c0-39.993,7.844-78.816,23.313-115.39
			c14.931-35.302,36.297-66.996,63.503-94.203c27.208-27.207,58.901-48.572,94.203-63.504
			c36.573-15.468,75.395-23.312,115.39-23.312c39.994,0,78.817,7.844,115.391,23.312c35.301,14.931,66.994,36.298,94.201,63.504
			c27.207,27.208,48.572,58.901,63.504,94.203c15.47,36.573,23.313,75.395,23.313,115.39s-7.843,78.817-23.313,115.391
			c-14.932,35.301-36.297,66.996-63.504,94.202c-27.207,27.208-58.9,48.572-94.201,63.505
			C2310.87,905.414,2272.047,913.257,2232.053,913.257z M2232.053,412.288c-112.795,0-204.561,91.766-204.561,204.561
			c0,112.796,91.765,204.561,204.56,204.561s204.562-91.765,204.562-204.561C2436.612,504.054,2344.848,412.288,2232.053,412.288z"
          />
        </g>
        <g id="path-20">
          <path
            id="path"
            fill="#9EE0FF"
            d="M1061.849,1058.903c-53.56,0-103.915-20.859-141.789-58.734
			c-37.875-37.875-58.732-88.229-58.732-141.787v-2.919c0-35.731-29.071-64.803-64.805-64.803c-35.734,0-64.807,29.07-64.807,64.803
			c0,3.458-2.805,6.263-6.262,6.263s-6.262-2.805-6.262-6.263c0-42.639,34.69-77.326,77.332-77.326
			c42.641,0,77.331,34.688,77.331,77.326v2.919c0,103.661,84.334,187.995,187.996,187.995c103.668,0,188.007-84.333,188.007-187.995
			v-2.919c0-71.616,27.892-138.946,78.531-189.587c50.643-50.641,117.974-78.53,189.591-78.53
			c71.613,0,138.944,27.891,189.586,78.53c50.643,50.643,78.531,117.972,78.531,189.587c0,3.458-2.805,6.263-6.263,6.263
			s-6.263-2.805-6.263-6.263c0-140.935-114.658-255.594-255.594-255.594c-140.938,0-255.598,114.659-255.598,255.594v2.919
			c0,53.56-20.859,103.912-58.736,141.787C1165.77,1038.044,1115.41,1058.903,1061.849,1058.903z"
          />
          <g id="circle">
            <circle fill="#004FBD" cx="725.452" cy="891.881" r="22.074" />
            <g>
              <path
                fill="#9EE0FF"
                d="M725.477,934.258c-0.333,0-0.666-0.004-1-0.012c-11.315-0.26-21.854-4.911-29.671-13.097
					c-7.816-8.188-11.979-18.929-11.718-30.245c0.26-11.314,4.912-21.853,13.098-29.669c8.187-7.816,18.922-11.992,30.242-11.718
					c11.314,0.261,21.853,4.911,29.669,13.098c7.818,8.186,11.979,18.926,11.72,30.24c-0.262,11.316-4.912,21.854-13.099,29.673
					C746.776,930.113,736.424,934.258,725.477,934.258z M725.435,862.031c-7.712,0-15.002,2.919-20.599,8.263
					c-5.766,5.506-9.042,12.928-9.226,20.897c-0.186,7.975,2.748,15.541,8.255,21.306c5.507,5.767,12.93,9.044,20.899,9.227
					c7.954,0.157,15.537-2.748,21.305-8.255c5.766-5.507,9.043-12.93,9.227-20.901l0,0c0.185-7.972-2.748-15.535-8.255-21.303
					c-5.507-5.766-12.93-9.042-20.9-9.226C725.904,862.035,725.669,862.031,725.435,862.031z"
              />
            </g>
          </g>
        </g>
        <g id="path-19">
          <circle fill="#FFFFFF" cx="1342.564" cy="397.286" r="11.791" />
          <circle fill="#004FBD" cx="1342.564" cy="426.764" r="11.791" />
          <circle fill="#00396E" cx="1342.564" cy="456.24" r="11.791" />
        </g>
        <g id="path-18">
          <circle fill="#FFFFFF" cx="1708.077" cy="912.229" r="11.791" />
          <circle fill="#004FBD" cx="1708.077" cy="941.705" r="11.792" />
          <circle fill="#00396E" cx="1708.077" cy="971.18" r="11.791" />
        </g>
        <g id="path-17">
          <circle fill="#FFFFFF" cx="626.221" cy="525.099" r="11.791" />
          <circle fill="#004FBD" cx="596.744" cy="525.099" r="11.791" />
          <circle fill="#00396E" cx="567.269" cy="525.099" r="11.791" />
        </g>
        <g id="path-16">
          <path
            fill="#9EE0FF"
            d="M592.467,773.141c-2.5,2.5-6.555,2.5-9.055,0l0,0c-2.501-2.501-2.501-6.555,0-9.057l41.189-41.191
			c2.502-2.5,6.557-2.5,9.057,0l0,0c2.5,2.501,2.5,6.557,0,9.058L592.467,773.141z"
          />
          <path
            fill="#9EE0FF"
            d="M633.659,764.084c2.5,2.499,2.5,6.554,0,9.055l0,0c-2.501,2.501-6.556,2.501-9.058,0l-41.19-41.19
			c-2.5-2.5-2.5-6.556,0-9.057l0,0c2.501-2.501,6.557-2.501,9.057,0L633.659,764.084z"
          />
        </g>
        <g id="path-15">
          <path
            fill="#FFD500"
            d="M1210.195,850.309c-1.336,1.339-3.508,1.339-4.843,0l0,0c-1.338-1.337-1.338-3.504,0-4.844l22.026-22.025
			c1.337-1.338,3.507-1.338,4.843,0l0,0c1.337,1.337,1.337,3.507,0,4.842L1210.195,850.309z"
          />
          <path
            fill="#FFD500"
            d="M1232.223,845.465c1.338,1.336,1.338,3.507,0,4.842l0,0c-1.338,1.337-3.507,1.337-4.843,0l-22.026-22.025
			c-1.338-1.338-1.338-3.505,0-4.842l0,0c1.339-1.338,3.507-1.338,4.844,0L1232.223,845.465z"
          />
        </g>
        <path
          id="path-14"
          fill="#00396E"
          d="M997.618,1049.891c-6.277,6.276-16.455,6.276-22.731,0l0,0
		c-6.277-6.278-6.277-16.455,0-22.732l103.398-103.399c6.279-6.278,16.455-6.278,22.733,0l0,0c6.276,6.277,6.276,16.455,0,22.731
		L997.618,1049.891z"
        />
        <g id="path-13">
          <g>
            <path
              fill="#004FBD"
              d="M496.726,593.246c-6.486,6.487-15.113,10.061-24.291,10.061c-18.938,0-34.35-15.41-34.35-34.351V284.955
				c0.001-18.941,15.41-34.35,34.351-34.351c9.177,0,17.804,3.574,24.291,10.062c6.488,6.488,10.062,15.114,10.062,24.29v284.002
				l0,0l0,0C506.787,578.131,503.214,586.758,496.726,593.246z M452.575,265.094c-5.306,5.305-8.227,12.358-8.227,19.861l-0.001,284
				c0,15.488,12.602,28.088,28.088,28.089c7.504,0.001,14.558-2.922,19.861-8.226c5.305-5.307,8.228-12.359,8.228-19.862l0,0
				V284.955c0.001-7.502-2.922-14.556-8.227-19.861c-5.305-5.305-12.358-8.227-19.862-8.227
				C464.934,256.867,457.88,259.789,452.575,265.094z"
            />
          </g>
          <g>
            <path
              fill="#FFFFFF"
              d="M528.938,616.742c-6.03,6.031-14.357,9.766-23.539,9.766c-18.366,0-33.308-14.939-33.308-33.307V309.2
				c-0.001-18.365,14.941-33.308,33.308-33.307c8.896-0.001,17.261,3.464,23.552,9.755s9.755,14.655,9.754,23.551V593.2
				C538.704,602.385,534.969,610.711,528.938,616.742z M484.807,288.611c-5.273,5.274-8.542,12.558-8.542,20.589v284.001
				c0,7.782,3.03,15.098,8.533,20.6c5.502,5.503,12.818,8.533,20.601,8.533c7.781,0,15.098-3.03,20.6-8.533
				c5.503-5.502,8.533-12.816,8.533-20.6h2.087h-2.087V309.2c0-7.781-3.03-15.096-8.532-20.599
				c-5.502-5.502-12.819-8.533-20.6-8.534C497.366,280.068,490.083,283.336,484.807,288.611z"
            />
          </g>
        </g>
        <g id="path-12">
          <g>
            <rect
              x="698.937"
              y="878.713"
              transform="matrix(0.7071 0.7071 -0.7071 0.7071 844.5409 -232.8544)"
              fill="#00396E"
              width="8.828"
              height="48.623"
            />
          </g>
          <g>
            <rect
              x="679.089"
              y="898.621"
              transform="matrix(0.7071 0.7071 -0.7071 0.7071 844.5624 -232.8876)"
              fill="#00396E"
              width="48.625"
              height="8.825"
            />
          </g>
        </g>
        <g id="path-11">
          <path
            fill="#004FBD"
            d="M1847.012,217.31c-34.729,0-62.981-28.256-62.981-62.987c0-34.731,28.254-62.987,62.981-62.987
			c34.731,0,62.987,28.256,62.987,62.987C1909.999,189.054,1881.743,217.31,1847.012,217.31z M1847.012,105.948
			c-26.671,0-48.371,21.7-48.371,48.374c0,26.674,21.7,48.375,48.371,48.375c26.674,0,48.375-21.701,48.375-48.375
			C1895.387,127.648,1873.686,105.948,1847.012,105.948z"
          />
        </g>
        <g id="path-10">
          <polygon
            fill="#D865DB"
            points="1364.271,297.344 1330.774,250.901 1337.548,246.016 1364.271,283.071 1394.385,241.321 
			1424.497,283.071 1454.612,241.322 1488.112,287.765 1481.342,292.65 1454.612,255.594 1424.497,297.344 1394.385,255.595 		"
          />
        </g>
        <g id="path-09">
          <g>
            <g>
              <path
                fill="#00396E"
                d="M261.77,1072.927c-63.053-11.664-117.788-47.184-154.124-100.016
					c-75.01-109.062-47.307-258.816,61.756-333.829c52.831-36.339,116.652-49.928,179.704-38.264
					c63.053,11.665,117.788,47.188,154.123,100.018c36.341,52.831,49.928,116.654,38.266,179.707
					c-11.664,63.051-47.187,117.787-100.021,154.123C388.643,1071.003,324.823,1084.591,261.77,1072.927z M346.827,613.134
					c-59.763-11.055-120.251,1.825-170.329,36.268C73.127,720.5,46.869,862.441,117.964,965.814
					c34.441,50.073,86.321,83.74,146.084,94.797c59.761,11.055,120.253-1.824,170.328-36.265
					c50.077-34.441,83.746-86.321,94.801-146.083c11.056-59.763-1.825-120.253-36.267-170.33
					C458.468,657.855,406.588,624.189,346.827,613.134z"
              />
            </g>
            <g>
              <path
                fill="#C96FA2"
                d="M271.356,1021.108c-49.209-9.104-91.929-36.824-120.291-78.061
					c-28.359-41.235-38.966-91.045-29.862-140.257s36.827-91.932,78.061-120.291c41.235-28.359,91.045-38.964,140.255-29.861
					c49.211,9.104,91.93,36.828,120.29,78.063c28.359,41.233,38.967,91.044,29.862,140.256
					c-9.103,49.209-36.828,91.928-78.061,120.289C370.377,1019.606,320.568,1030.212,271.356,1021.108z M332.305,691.64
					c-38.792-7.176-78.059,1.188-110.563,23.542c-32.506,22.354-54.361,56.031-61.537,94.825
					c-7.176,38.794,1.185,78.062,23.543,110.565c22.355,32.507,56.033,54.358,94.825,61.536
					c38.794,7.177,78.058-1.184,110.563-23.541c32.506-22.355,54.361-56.033,61.537-94.824
					c7.177-38.794-1.185-78.061-23.542-110.565C404.773,720.672,371.098,698.816,332.305,691.64z"
              />
            </g>
            <g>
              <path
                fill="#004FBD"
                d="M282.168,962.67c-33.602-6.216-62.771-25.146-82.135-53.301c-19.365-28.155-26.606-62.166-20.39-95.768
					c6.216-33.603,25.145-62.771,53.3-82.134c28.155-19.364,62.166-26.605,95.767-20.39c33.602,6.214,62.771,25.144,82.136,53.298
					c19.364,28.155,26.606,62.166,20.391,95.767c-6.217,33.604-25.146,62.773-53.301,82.138
					C349.781,961.644,315.771,968.886,282.168,962.67z M326.432,723.395c-30.311-5.606-60.993,0.927-86.392,18.394
					c-25.397,17.467-42.474,43.781-48.081,74.092c-5.608,30.313,0.925,60.994,18.393,86.395
					c17.47,25.397,43.783,42.474,74.095,48.079c30.313,5.608,60.993-0.925,86.392-18.393c25.397-17.468,42.476-43.782,48.082-74.096
					c5.608-30.313-0.925-60.992-18.393-86.393C383.058,746.076,356.745,729.002,326.432,723.395z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M289.28,924.229c-23.333-4.317-43.587-17.463-57.034-37.014c-13.448-19.55-18.476-43.166-14.161-66.499
					c4.317-23.333,17.461-43.591,37.013-57.037c19.549-13.445,43.165-18.473,66.498-14.154
					c23.333,4.315,43.588,17.459,57.035,37.008c27.755,40.361,17.504,95.777-22.851,123.535
					C336.227,923.514,312.61,928.543,289.28,924.229z M314.382,788.523c-12.915-2.391-25.987,0.392-36.807,7.836
					c-10.824,7.44-18.1,18.654-20.489,31.57c-2.389,12.912,0.395,25.984,7.837,36.808c7.445,10.821,18.656,18.101,31.572,20.488
					c12.913,2.39,25.988-0.396,36.812-7.839c22.336-15.363,28.01-46.04,12.647-68.382
					C338.511,798.188,327.297,790.913,314.382,788.523z"
              />
            </g>
          </g>
          <circle fill="#00396E" cx="464.619" cy="667.946" r="23.776" />
        </g>
        <g id="path-08">
          <g>
            <g>
              <path
                fill="#FFD500"
                d="M241.07,337.803h-9.391c0-70.332-57.218-127.55-127.549-127.55c-70.333,0-127.55,57.218-127.55,127.55
					h-9.391c0-75.508,61.431-136.94,136.941-136.94C179.638,200.863,241.07,262.294,241.07,337.803z"
              />
            </g>
            <g>
              <path
                fill="#FFD500"
                d="M276.528,337.803h-9.391c0-89.883-73.126-163.007-163.007-163.007
					c-89.883,0-163.009,73.125-163.009,163.007h-9.391c0-95.06,77.337-172.396,172.397-172.396S276.528,242.743,276.528,337.803z"
              />
            </g>
            <g>
              <path
                fill="#FFD500"
                d="M312.131,337.803h-9.39c0-109.515-89.096-198.612-198.612-198.612S-94.483,228.288-94.483,337.803h-9.39
					c0-114.693,93.309-208.001,208.001-208.001C218.823,129.801,312.131,223.11,312.131,337.803z"
              />
            </g>
            <g>
              <path
                fill="#FFD500"
                d="M346.44,337.803h-9.391c0-128.433-104.486-232.921-232.919-232.921S-128.791,209.37-128.791,337.803
					h-9.391c0-133.61,108.701-242.311,242.312-242.311C237.74,95.492,346.44,204.192,346.44,337.803z"
              />
            </g>
            <g>
              <path
                fill="#FFD500"
                d="M379.379,337.803h-9.39c0-146.595-119.265-265.859-265.858-265.859
					c-146.596,0-265.86,119.264-265.86,265.859h-9.39c0-151.772,123.477-275.25,275.25-275.25
					C255.902,62.552,379.379,186.03,379.379,337.803z"
              />
            </g>
          </g>
          <g>
            <rect
              x="-171.418"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="345.632"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="345.632"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="345.632"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="345.632"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="345.632"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="363.45"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="363.45"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="363.45"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="363.45"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="363.45"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="381.268"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="381.268"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="381.268"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="381.268"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="381.268"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="399.085"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="399.085"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="399.085"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="399.085"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="399.085"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="413.657"
              fill="#FFD500"
              width="9.988"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="413.657"
              fill="#FFD500"
              width="9.987"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="413.657"
              fill="#FFD500"
              width="9.988"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="413.657"
              fill="#FFD500"
              width="9.988"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="413.657"
              fill="#FFD500"
              width="9.988"
              height="9.986"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="428.229"
              fill="#FFD500"
              width="9.988"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="428.229"
              fill="#FFD500"
              width="9.987"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="428.229"
              fill="#FFD500"
              width="9.988"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="428.229"
              fill="#FFD500"
              width="9.988"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="428.229"
              fill="#FFD500"
              width="9.988"
              height="9.989"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="442.802"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-105.541"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="-33.109"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="442.802"
              fill="#FFD500"
              width="9.987"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="442.802"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="442.802"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="442.802"
              fill="#FFD500"
              width="9.988"
              height="9.987"
            />
          </g>
          <g>
            <rect
              x="369.69"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="336.751"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="302.442"
              y="457.593"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="266.838"
              y="457.593"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="231.38"
              y="457.593"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M374.685,483.106L374.685,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.987v4.993
				C369.69,480.872,371.926,483.106,374.685,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M341.745,483.106L341.745,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.987v4.993
				C336.751,480.872,338.987,483.106,341.745,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M307.436,483.106L307.436,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.988v4.993
				C302.442,480.872,304.679,483.106,307.436,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M271.832,483.106L271.832,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.988v4.993
				C266.838,480.872,269.075,483.106,271.832,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M236.375,483.106L236.375,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.988v4.993
				C231.38,480.872,233.616,483.106,236.375,483.106z"
            />
          </g>
          <g>
            <rect
              x="-33.11"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-68.566"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-105.351"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-138.479"
              y="457.593"
              fill="#FFD500"
              width="9.988"
              height="9.988"
            />
          </g>
          <g>
            <rect
              x="-171.418"
              y="457.593"
              fill="#FFD500"
              width="9.987"
              height="9.988"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M-28.116,483.106L-28.116,483.106c2.758,0,4.994-2.234,4.994-4.993v-4.993h-9.987v4.993
				C-33.11,480.872-30.874,483.106-28.116,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M-63.573,483.106L-63.573,483.106c2.758,0,4.993-2.234,4.993-4.993v-4.993h-9.987v4.993
				C-68.566,480.872-66.332,483.106-63.573,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M-100.357,483.106L-100.357,483.106c2.759,0,4.995-2.234,4.995-4.993v-4.993h-9.988v4.993
				C-105.351,480.872-103.115,483.106-100.357,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M-133.486,483.106L-133.486,483.106c2.759,0,4.995-2.234,4.995-4.993v-4.993h-9.988v4.993
				C-138.479,480.872-136.244,483.106-133.486,483.106z"
            />
          </g>
          <g>
            <path
              fill="#FFD500"
              d="M-166.425,483.106L-166.425,483.106c2.759,0,4.994-2.234,4.994-4.993v-4.993h-9.987v4.993
				C-171.418,480.872-169.183,483.106-166.425,483.106z"
            />
          </g>
        </g>
        <circle
          id="path-07"
          fill="#FFD500"
          cx="1402.837"
          cy="986.824"
          r="84.778"
        />
        <g id="path-06">
          <path
            fill="#FFFFFF"
            d="M1533.846,1024.032c-47.676,0-86.464-38.788-86.464-86.465c0-47.678,38.788-86.466,86.464-86.466
			c47.678,0,86.465,38.788,86.465,86.466C1620.311,985.244,1581.521,1024.032,1533.846,1024.032z M1533.846,854.477
			c-45.816,0-83.091,37.274-83.091,83.092c0,45.816,37.272,83.091,83.091,83.091s83.092-37.273,83.092-83.091
			S1579.663,854.477,1533.846,854.477z"
          />
        </g>
        <g id="path-05">
          <circle fill="#A3A3A3" cx="813.691" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="844.025" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="874.358" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="904.691" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="935.024" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="965.357" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="995.691" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1026.024" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1056.355" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1086.689" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1117.024" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1147.357" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1177.689" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="1208.021" cy="112.884" r="4.334" />
          <circle fill="#A3A3A3" cx="813.691" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="844.025" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="874.358" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="904.691" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="935.024" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="965.357" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="995.691" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1026.024" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1056.355" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1086.689" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1117.024" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1147.357" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1177.689" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="1208.021" cy="139.145" r="4.334" />
          <circle fill="#A3A3A3" cx="813.691" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="844.025" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="874.358" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="904.691" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="935.024" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="965.357" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="995.691" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1026.024" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1056.355" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1086.689" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1117.024" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1147.357" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1177.689" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="1208.021" cy="165.405" r="4.335" />
          <circle fill="#A3A3A3" cx="813.691" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="844.025" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="874.358" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="904.691" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="935.024" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="965.357" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="995.691" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1026.024" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1056.355" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1086.689" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1117.024" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1147.357" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1177.689" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="1208.021" cy="191.666" r="4.335" />
          <circle fill="#A3A3A3" cx="813.691" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="844.025" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="874.358" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="904.691" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="935.024" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="965.357" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="995.691" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1026.024" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1056.355" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1086.689" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1117.024" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1147.357" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1177.689" cy="217.927" r="4.335" />
          <circle fill="#A3A3A3" cx="1208.021" cy="217.927" r="4.335" />
        </g>
        <g id="path-04">
          <path
            fill="#E8E31F"
            d="M1667.094,247.61l-62.954-62.954l-62.952,62.954l-38.906-38.906l62.953-62.954l-62.953-62.953
			l38.906-38.905l62.952,62.953l62.954-62.953L1706,82.798l-62.953,62.953L1706,208.705L1667.094,247.61z M1604.141,176.426
			l62.954,62.953l30.675-30.675l-62.954-62.954l62.954-62.953l-30.675-30.675l-62.954,62.953l-62.953-62.953l-30.675,30.675
			l62.954,62.953l-62.954,62.954l30.675,30.675L1604.141,176.426z"
          />
        </g>
        <path
          id="path-03"
          fill="#00396E"
          d="M951.155,957.24c-6.7,6.7-17.563,6.7-24.264,0l0,0c-6.7-6.7-6.7-17.563,0-24.264
		l110.36-110.359c6.7-6.7,17.563-6.7,24.264,0l0,0c6.7,6.7,6.7,17.563,0,24.265L951.155,957.24z"
        />
        <path
          id="path-02"
          fill="#9EE0FF"
          d="M910.678,916.765c-6.7,6.699-17.563,6.699-24.262,0l0,0c-6.701-6.7-6.701-17.563,0-24.264
		l110.36-110.36c6.699-6.699,17.563-6.699,24.264,0l0,0c6.7,6.7,6.7,17.563,0,24.265L910.678,916.765z"
        />
        <g id="path01">
          <g>
            <path
              fill="#004FBD"
              d="M922.013,972.922c-10.604,1.621-21.277,1.383-31.722-0.707c-2.213-0.442-3.648-2.598-3.208-4.813
				c0.444-2.214,2.598-3.648,4.813-3.206c9.505,1.901,19.222,2.119,28.882,0.644c45.461-6.945,76.795-49.581,69.85-95.042
				c-0.341-2.231,1.19-4.317,3.424-4.66c2.231-0.34,4.317,1.192,4.659,3.426C1006.337,918.48,971.931,965.297,922.013,972.922z
				 M867.69,963.633c-0.844,0.129-1.731-0.004-2.541-0.437c-9.451-5.046-17.878-11.638-25.046-19.584
				c-1.512-1.678-1.379-4.262,0.298-5.773c1.68-1.513,4.262-1.379,5.774,0.298c6.532,7.242,14.211,13.247,22.825,17.849
				c1.991,1.063,2.744,3.541,1.68,5.531C870.051,962.699,868.92,963.444,867.69,963.633z M822.313,899.649
				c-2.232,0.341-4.318-1.19-4.659-3.425c-7.626-49.918,26.778-96.732,76.696-104.36c13.674-2.088,27.277-1.11,40.434,2.904
				c2.16,0.659,3.376,2.943,2.717,5.104c-0.66,2.158-2.943,3.376-5.104,2.717c-11.974-3.655-24.358-4.544-36.811-2.642
				c-45.461,6.944-76.795,49.58-69.849,95.041C826.077,897.224,824.544,899.31,822.313,899.649z M988.928,851.289
				c-1.785,0.271-3.6-0.668-4.359-2.4c-6.367-14.51-16.745-26.877-30.013-35.767c-1.875-1.257-2.378-3.797-1.12-5.673
				s3.798-2.376,5.674-1.119c14.56,9.756,25.951,23.336,32.946,39.271c0.907,2.068-0.034,4.479-2.101,5.389
				C989.619,851.137,989.274,851.236,988.928,851.289z"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {}
</script>

<style>
#path-21,
#path-23,
#path-24 {
  display: none;
}

#path-20 {
  transform: translate(30px, -180px);
}

#path01,
#path-02,
#path-03,
#path-14,
#path-13 {
  display: none;
}

#path-07 {
  transform: translate(0px, -180px);
}
</style>
